import React, { Component } from 'react'
import Envelope from 'images/envelope.png'

class FooterForm extends Component {
  render() {
    return (
      <div className="FooterForm">
        <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
          <input type="hidden" name="oid" value="00D2E000001Hqx6" />
          <input type="hidden" name="retURL" value="https://apruvd.com/success" />
          <input type="hidden" name="lead_source" value="Website Newsletter Signup" />
          <input className="email" name="email" type="text" placeholder="email address" required />
          <input className="d-none" type="submit" value="Submit" />
          <input className="submit-envelope" type="image" src={Envelope} border="0" alt="Submit" />
        </form>
      </div>
    )
  }
}

export default FooterForm
