import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import PageHeader from 'components/PageHeader'
import Footer from 'components/Footer'

class PrivacyPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="PrivacyPolicy">
        <PageHeader />
        <Row className="wot">
          <Col md={12}>
            <h3>APRUVD, LLC</h3>
            <h3>Privacy Notice</h3>
            <h6>LAST REVISION DATE: OCTOBER 12, 2022</h6>
            <p>
              Welcome to the Apruvd, LLC, an Arizona limited liability company, ("Apruvd, "we," or "us") website.
              Your privacy is very important to all of us at Apruvd. This page explains the policy governing online
              data collection and usage adopted by Apruvd (the "Privacy Notice"), which includes the software,
              website, and online services that make up Apruvd (collectively the "Services"). This Privacy Notice does
              not govern collection of data offline. By using the Services, you consent to the data practices described
              in this Privacy Notice.
            </p>
            <p>
              FOR MORE INFORMATION OR IF YOU HAVE ANY QUESTIONS ABOUT THIS PRIVACY NOTICE, PLEASE
              CONTACT US AT <a href="mailto:support@apruvd.com">support@apruvd.com</a>.
            </p>
            <h4>1. PERSONAL INFORMATION</h4>
            <p>
              This Privacy Notice explains to you how your personal information is protected, collected, and used.
              Personal information is information about you that is personally identifiable, such as your name,
              address, phone number, email address, and other information that is not otherwise publicly available
              ("Personal Information"). The privacy practices of this Privacy Notice apply to our services and products
              available through this website, including any platforms accessible through this website, and any mobile
              applications offered by Apruvd (collectively, the "Site"), and your use of the Site. This Privacy Notice
              also describes the types of information we may collect from you or that you may provide when you visit
              the Site, and our practices for collecting, using, maintaining, protecting, and disclosing that information.
            </p>
            <p>
              Please read this Privacy Notice carefully to understand our policies and practices regarding your
              information and how we will treat it. By visiting the Site, you agree to be bound by the terms and
              conditions of this Privacy Notice. If you do not agree, please do not use or access this Site. From time 
              to time, Apruvd may modify this Privacy Notice and post such modifications here on this Site. Any
              modifications are effective immediately, and your use of this Site after any such modification
              constitutes your acceptance of the modified Privacy Notice. By submitting Personal Information to us
              through this Site, you expressly consent to our use and disclosure of your Personal Information in
              accordance with this Privacy Notice. This Privacy Notice is incorporated into and subject to the terms of
              the Apruvd Terms of Use and Service Agreement, available at <a href="/terms-of-use">https://www.apruvd.com/terms-of-use</a>.
            </p>
            <h4>2. INFORMATION COLLECTION &amp; USE</h4>
            <p>
              Apruvd collects Personal Information from you through your registration process to use the Services.
              The legal basis or business purpose for collecting Personal Information from you through the Services 
              is for our legitimate business interests, namely, to allow us to provide the Services that will most 
              likely meet your needs and preferences and to monitor and improve our Services. We only collect Personal 
              Information about you that we consider necessary for achieving this purpose.
            </p>
            <p>
              In general, you can browse the Site and "opt-out" from providing us with any Personal Information. Once 
              you agree to provide us with Personal Information, you are no longer anonymous to us. If you choose to use 
              and/or purchase certain Services we may require you to provide contact and identity information, billing
              information, and other Personal Information as indicated on the forms throughout the Site. Where
              possible, we indicate which fields are required and which are optional. You always have the option not
              to provide information by choosing not to use or purchase a particular service.
            </p>
            <p>
              We also collect information about you that does not itself identify you individually and aggregate 
              information, which is information about use of our Services, such as pages visited on our Site. The 
              information we collect depends on the purpose and nature of your interactions with the Site and on what 
              services and features you use on our Services.
            </p>
            <p>
              Under some circumstances, we may require some additional financial information. We use your
              financial information, including credit card information, to verify the accuracy of your name, address
              and other information, as well as to bill you for your use of our services and products.
            </p>
            <p>
              We may track certain behavior on the Site. We collect several types of information from and about users
              of our website, including information:
            </p>
            <ul>
              <li>
                by which you may be personally identified, such as name, postal address, e-mail address, telephone 
                number, or any other identifier by which you may be contacted online or offline; about you but that 
                individually does not personally identify you; and/or
              </li>
              <li>
                information about your internet connection, IP address, and the equipment you use to access our
                Site and usage details.
              </li>
            </ul>
            <p>
              We collect this information:
            </p>
            <ul>
              <li>
                directly from you when you provide it to us through filling in forms on our website;
              </li>
              <li>
                automatically as you navigate through the Site. Information collected automatically may include
                usage details;
              </li>
              <li>
                from third parties, for example, our business partners.
              </li>
              <li>
                From Platform users sending email or text messages marketing campaigns to a target through our Services;
              </li>
              <li>
                In email, text, and other electronic messages between you and the Site; and
              </li>
              <li>
                Through mobile and desktop applications by use of the Services.
              </li>
            </ul>
            <p>
              We use this information to do internal research on our users' demographics, interests, and behavior to
              better understand our customers.
            </p>
            <p>
              If you send us personal correspondence, such as emails or letters, or if other users or third parties send
              us correspondence about your activities or postings on the Site, we may collect and retain such
              information in a file specific to you.
            </p>
            <p>
              We reserve the right, at any time and without notice to you, to add to, change, update, or modify this 
              Privacy Notice. If we decide to change our Privacy Notice, we will post a new notice on our Site and change 
              the date at the top of the Privacy Notice. Any change, update or modification will be effective immediately 
              upon posting on our Site and will apply to any personal information provided to us on and after that date. 
              Your continued use of this Services after we make changes is deemed to be acceptance of those changes, so 
              please check the Privacy Notice periodically for updates.
            </p>
            <h4>3. USE OF YOUR PERSONAL INFORMATION</h4>
            <p>
              We use your Personal Information, and other information we obtain from your current and past activities
              on the Site for our legitimate business purposes, to: resolve disputes; troubleshoot problems; measure 
              consumer interest in our products and services; inform you about online and offline offers, products, 
              services, and updates; customize your experience on the Site and with Apruvd's Services; detect and protect 
              us against error, fraud, and other criminal activity; enforce our Terms of Use and Service Agreement; 
              provide you with notices about your account/subscription; carry out our obligations and enforce our rights 
              arising from any contracts entered into between you and us, including for billing and collections; and as 
              otherwise described to you at the time of collection. We may compare and review your Personal Information 
              for errors, omissions, and accuracy.
            </p>
            <p>
              You agree that we may use your Personal Information, including your email address, to improve our
              marketing efforts, to analyze the Site usage, to improve the Services, and to customize Apruvd's
              Content, layout, and services. These uses improve the Site and better tailor it to meet your needs, so as
              to provide you with a smooth, efficient, safe, and customized experience while using the Site and/or the
              Services.
            </p>
            <p>
              We have the right to de-identify or aggregate data. Deidentified information is information that cannot 
              reasonably be used to infer information about, or otherwise be linked to, a particular identifiable person. 
              To the extent that we deidentify your Personal Information we will (i) take reasonable measures to ensure 
              that the information cannot be associated with an individual, (ii) publicly commit to maintain and use the 
              information in deidentified form and not to attempt to reidentify it, (iii) implement technical safeguard 
              that prohibit reidentification, (iv) implement business processes that specifically prohibit
              reidentification, (v) implement business processes that prevent inadvertent release of deidentified 
              information, (vi) make no attempt to reidentify the information, and (vii) contractually obligate any 
              recipients of the deidentified information to comply with all provisions in this Privacy Notice. For the 
              avoidance of doubt, de-identified data is not considered Personal Data.
            </p>
            <h4>4. COMMUNICATIONS FROM THE SITE OR APRUVD</h4>
            <p>
              We may occasionally send you information on products or services. Out of respect for your privacy, we
              provide you a way to unsubscribe from each of these communications. If you no longer wish to receive
              such communications, you may opt-out of receiving them by following the instructions included in each
              such communication or by contacting us.
            </p>
            <p>
              You will be notified when your Personal Information is collected by any third party that is not our agent/
              service provider, so you can make an informed choice as to whether or not to share your information
              with that party.
            </p>
            <p>
              We will send you strictly service-related announcements on rare occasions when it is necessary to do so.
              For instance, if access to our services and products is temporarily suspended for maintenance, we might
              send you an email. Generally, you may not opt-out of these communications, which are not promotional
              in nature. If you do not wish to receive them, you have the option to deactivate your account and
              subscription for our products.
            </p>
            <h4>5. INFORMATION SHARING AND DISCLOSURE</h4>
            <p>
              We do not sell, rent, or share any of your Personal Information, including your email address, to third 
              parties for their marketing purposes without your explicit consent. However, the following describes some 
              of the ways that your Personal Information may be disclosed in the normal scope of business to provide our 
              Services and products to customers:
            </p>
            <ul>
              <li>
                <strong>Purpose:</strong> We may disclose your Personal Information to fulfill the purpose for which you
                provide it.
              </li>
              <li>
                <strong>External Service Providers:</strong> There may be separate services offered by third parties that we
                refer to as external service providers that may be complementary to your use of the Site. If you
                choose to use these separate services or products, disclose information to the external service
                providers, and/or grant them permission to collect information about you, then their use of your
                information is governed by their Privacy Notice. We do not disclose your Personal Information,
                including your email address, to external service providers unless you provide your explicit
                consent. With your consent, we may provide some of your Personal Information to the external
                service provider offering such services, for your convenience. To prevent our disclosure of your
                Personal Information to an external service provider, you can decline such consent or simply not
                use their services or products. Because we do not control the privacy practices of these third
                parties, you should evaluate their practices before deciding to use their services.
              </li>
              <li>
                <strong>Internal Service Providers:</strong> We may use third parties that we refer to as internal service
                providers to facilitate or outsource one or more aspects of the business, product, and service
                operations that we provide to you and therefore we may provide some of your Personal
                Information, including your email address, directly to these internal service providers. These
                internal service providers are subject to confidentiality agreements with us and other legal
                restrictions that prohibit their use of the information we provide them for any other purpose
                except to facilitate the specific outsourced Apruvd related operation, unless you have explicitly
                agreed or given your prior permission to them for additional uses. If you provide additional
                information to an internal service provider directly, then their use of your Personal Information is
                governed by their applicable Privacy Notice.
              </li>
              <li>
                <strong>Buyers or Successors:</strong> To a buyer or other successor in the event of a merger, divestiture,
                restructuring, reorganization, dissolution, or other sale or transfer of some or all of Apruvd
                LLC's assets, whether as a going concern or as a part of bankruptcy, liquidation, or similar
                proceeding in which personal information held by Apruvd about our Site users is among the
                assets transferred.
              </li>
              <li>
                <strong>Legal Requests:</strong> Apruvd cooperates with law enforcement inquiries, as well as other third
                parties to enforce laws, such as: intellectual property rights, fraud and other rights, to help protect
                you, other users, and Apruvd from bad actors. Therefore, in response to a verified request by law
                enforcement or other government officials relating to a criminal investigation or alleged illegal
                activity, we can (and you authorize us to) disclose your name, city, state, telephone number,
                email address, username history, and fraud complaints without a subpoena. Without limiting the
                above, in an effort to respect your privacy, we will not otherwise disclose your Personal
                Information to law enforcement or other government officials without a subpoena, court order or
                substantially similar legal procedure, except when we believe in good faith that the disclosure of
                information is necessary to prevent imminent physical harm or financial loss, or report suspected
                illegal activity.
              </li>
              <li>
                <strong>Other Disclosures:</strong> We may also disclose your Personal Information: (i) to enforce or apply our
                Terms of Use and Service Agreement or other agreements, including for billing and collection
                purposes; or (ii) if we believe disclosure is necessary or appropriate to protect the rights,
                property, or safety of Apruvd LLC, our customers, or others.
              </li>
            </ul>
            <p>
              Due to the existing regulatory environment, we cannot ensure that all of your private communications
              and other Personal Information will never be disclosed in ways not otherwise described in this Privacy
              Notice. By way of example (without limiting the foregoing), we may be forced to disclose Personal
              Information, including your email address, to the government or third parties under certain
              circumstances, third parties may unlawfully intercept or access transmissions or private
              communications, or users may abuse or misuse your Personal Information that they collect from the
              Site. Therefore, although we use industry standard practices to protect your privacy, we do not promise,
              and you should neither rely upon nor expect, that your Personal Information or private communications
              will always remain private.
            </p>
            <p>
              We may disclose aggregated information about our users, and information that does not identify any
              individual, without restriction.
            </p>
            <h5>Your California Privacy Rights</h5>
            <p>
              This section of the Privacy Notice applies solely to visitors, users, and others who reside in the 
              State of California ("consumers" or "you"). We adopt this notice to comply with the California Consumer 
              Privacy Act of 2018 ("CCPA") and other California privacy laws. Any terms defined in the CCPA have 
              the same meaning when used in this notice.
            </p>
            <p>
              California Civil Code Section § 1798.83 permits users of our website that are California residents to
              request certain information regarding our disclosure of personal information to third parties for their
              direct marketing purposes. To make such a request, please send an email to <a href="mailto:support@apruvd.com">
              support@apruvd.com</a> or write us at: Apruvd, LLC Attn: Compliance Department, 2999 North 44th Street, 
              Suite 630 Phoenix, AZ 85018. Please note that we do not share your  personal information to any third-party 
              for marketing or advertising purposes.
            </p>
            <p>
              As stated above in Section 2, Apruvd collects Personal Information from you through your registration 
              process to use the Services. The legal basis or business purpose for collecting Personal Information 
              from you through the Services is for our legitimate business interests, namely, to allow us to provide 
              the Services that will most likely meet your needs and preferences and to monitor and improve our Services. 
              We only collect Personal Information about you that we consider necessary for achieving this purpose.
            </p>
            <p>
              Under some circumstances, we may require some additional financial information. We use your financial 
              information, including credit card information, to verify the accuracy of your name, address and other 
              information, as well as to bill you for your use of our services and products. We obtain the categories 
              of personal information listed directly below from you, our customer or their agents, indirectly from you, 
              our customers or their agents in the course of providing services to you or them, directly from our Site, 
              or from third parties that interact with us in connection with the services we perform.
            </p>
            <p>
              We collect information that identifies, relates to, describes, references, is capable of being associated 
              with, or could reasonably be linked, directly or indirectly, with a particular consumer or device ("personal 
              information"). Personal information does not include publicly available information from government records, 
              de-identified or aggregated consumer information, or information excluded from CCPA's scope, including 
              personal information covered by sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), 
              the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's 
              Privacy Protection Act of 1994.
            </p>
            <p>
              We may use or disclose the personal information we collect for one or more of the following legitimate business purposes:
            </p>
            <ul>
              <li>
                To fulfill or meet the reason for which the information is provided. For example, if you provide us with personal 
                information in order for us to verify identity or provide fraud prevention services, we will use that information 
                to provide the services.
              </li>
              <li>
                To provide you with information, products or services that you request from us.
              </li>
              <li>
                To provide you with email alerts, event registrations and other notices concerning our products or services, 
                or events or news, that may be of interest to you.
              </li>
              <li>
                To carry out our obligations and enforce our rights arising from any contracts entered into between you and 
                us, including for billing and collections.
              </li>
              <li>
                To improve our website and present its contents to you.
              </li>
              <li>
                For testing, research, analysis and product development.
              </li>
              <li>
                As necessary or appropriate to protect the rights, property or safety of us, our clients or others.
              </li>
              <li>
                To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.
              </li>
              <li>
                As described to you when collecting your personal information or as otherwise set forth in the CCPA.
              </li>
            </ul>
            <p>
              We may disclose your personal information to a third party for a business purpose. When we disclose personal 
              information for a business purpose, we enter a contract that describes the purpose and requires the recipient 
              to both keep that personal information confidential and not use it for any purpose except performing the 
              contract. In particular, we have collected and disclosed to a third party the following categories of personal 
              information from consumers within the last twelve (12) months:
            </p>
            <table>
              <thead>
                <tr>
                  <th><strong>Category</strong></th>
                  <th><strong>Examples</strong></th>
                  <th><strong>Collected</strong></th>
                  <th><strong>Disclosed to a third party</strong></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>A. Identifiers.</td>
                  <td>
                    A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, 
                    email address, account name, Social Security number, driver's license number, passport number, or other 
                    similar identifiers.
                  </td>
                  <td>YES</td>
                  <td>
                    Yes, disclosed to our service providers and third parties to whom you or your agents authorize us to disclose 
                    your personal information in connection with products or services we provide to you.
                  </td>
                </tr>
                <tr>
                  <td>B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</td>
                  <td>
                    A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport 
                    number, driver's license or state identification card number, insurance policy number, education, employment, employment 
                    history, bank account number, credit card number, debit card number, or any other financial information, medical information, 
                    or health insurance information. Some personal information included in this category may overlap with other categories.
                  </td>
                  <td>YES</td>
                  <td>
                    Yes, disclosed to our service providers and third parties to whom you or your agents authorize us to disclose your 
                    personal information in connection with products or services we provide to you.
                  </td>
                </tr>
                <tr>
                  <td>C. Protected classification characteristics under California or federal law.</td>
                  <td>
                    Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical 
                    condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth 
                    and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic 
                    information).
                  </td>
                  <td>YES</td>
                  <td>
                    Yes, disclosed to our service providers and third parties to whom you or your agents authorize us to disclose your personal 
                    information in connection with products or services we provide to you.
                  </td>
                </tr>
                <tr>
                  <td>D. Commercial information.</td>
                  <td>
                    Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories 
                    or tendencies.
                  </td>
                  <td>YES</td>
                  <td>No.</td>
                </tr>
                <tr>
                  <td>E. Biometric information.</td>
                  <td>
                    Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other 
                    identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, 
                    or other physical patterns, and sleep, health, or exercise data.
                  </td>
                  <td>YES</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>F. Internet or other similar network activity.</td>
                  <td>Browsing history, search history, information on a consumer's interaction with a website, application, or advertisement.</td>
                  <td>YES</td>
                  <td>Yes, disclosed to our service providers.</td>
                </tr>
                <tr>
                  <td>G. Geolocation data.</td>
                  <td>Physical location or movements.</td>
                  <td>NO</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>H. Sensory data.</td>
                  <td>Audio, electronic, visual, thermal, olfactory, or similar information.</td>
                  <td>NO</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>I. Professional or employment-related information.</td>
                  <td>Current or past job history or performance evaluations.</td>
                  <td>NO</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>
                    J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 
                    C.F.R. Part 99)).
                  </td>
                  <td>
                    Education records directly related to a student maintained by an educational institution or party acting on its behalf, 
                    such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, 
                    or student disciplinary records.
                  </td>
                  <td>NO</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>K. Inferences drawn from other personal information.</td>
                  <td>
                    Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, 
                    intelligence, abilities, and aptitudes.
                  </td>
                  <td>NO</td>
                  <td>N/A</td>
                </tr>
              </tbody>
            </table>
            <p>
              If you are a resident of California, you have other rights under the CCPA:
            </p>
            <ul>
              <li>
                <strong>Right of Access:</strong> You can access your collected personal information by contacting us  
                at <a href="mailto:support@apruvd.com">support@apruvd.com</a>.
              </li>
              <li>
                <strong>Right to correct, update, or delete:</strong> You can correct, update or request deletion of your 
                personal by contacting us at <a href="mailto:support@apruvd.com">support@apruvd.com</a>. We can't make changes 
                to or delete your information in some situations where it is necessary for us to maintain your information, 
                for example if we need the information to complete transactions, continue providing services to you, or comply 
                with applicable law. As an example, in the course of providing fraud prevention services to you or our customers, 
                we may be required to retain your personal information for up to 180 days.
              </li>
              <li>
                <strong>Right to Request Disclosure of Information Collected:</strong> Please contact us at 
                <a href="mailto:support@apruvd.com"> support@apruvd.com</a> to request further information about the categories 
                of personal information we have collected about you, where we collected your personal information, and for what 
                purpose we use your personal information.
              </li>
              <li>
                <strong>Right to Disclosure of Information Sold and Right to Opt-Out:</strong> You have the right to know what 
                information of yours we have sold, and you have the right to opt-out of any sale of your information. We do not 
                sell any of your information. If you have any questions about these rights, please contact us at 
                <a href="mailto:support@apruvd.com"> support@apruvd.com</a>.
              </li>
              <li>
                <strong>Right to Non-Discrimination:</strong> We do not and will not discriminate against you if you exercise 
                your rights under the CCPA.
              </li>
            </ul>
            <p>
              When you contact us regarding any of your rights under the CCPA, we will verify your identity before we provide 
              any information. If you have any questions or comments about your rights under the CCPA, please contact us at 
              our toll free telephone number (480) 477-9150 or <a href="mailto:support@apruvd.com">support@apruvd.com</a>.
            </p>
            <h5>
              Colorado Privacy Act ("CPA"), Nevada Privacy of Information Collected on the Internet from Consumers Act 
              ("NPICICA"), and Virginia Consumer Data Protection Act ("VCDPA") Disclosures
            </h5>
            <h5>Categories of Personal Information Collected</h5>
            <p>
              We collect the following categories of information: identifiers (such as your name and email address), personal 
              information (such as your name and contact information), internet or other similar network activity, geolocation 
              data, and inferences drawn from the collected Personal Information. This information is collected directly from 
              you when you provide it to us (for example when you submit a form requesting more information) or automatically 
              as you navigate through the Services. We use this information for one or more legitimate business purposes, 
              including to improve our Services and offer information about our Services to you.
            </p>
            <h5>Rights of Colorado, Nevada, and Virginia Residents</h5>
            <p>
              If you are a resident of Colorado, Nevada, or Virginia, you have other rights under your respective states' 
              consumer privacy statutes:
            </p>
            <ul>
              <li>
                <strong>Right of Access:</strong> You can access your collected personal information by contacting us  
                at <a href="mailto:support@apruvd.com">support@apruvd.com</a>.
              </li>
              <li>
                <strong>Right to correct, update, or delete:</strong> You can correct, update or request deletion of your 
                personal by contacting us at <a href="mailto:support@apruvd.com">support@apruvd.com</a>. We can't make changes 
                to or delete your information in some situations where it is necessary for us to maintain your information, 
                for example if we need to comply with applicable law. 
              </li>
              <li>
                <strong>Right to Request Disclosure of Information Collected:</strong> Please contact us at <a href="mailto:support@apruvd.com">
                support@apruvd.com</a> to request further information about the categories of personal information we have 
                collected about you, where we collected your personal information, and for what purpose we use your personal 
                information.
              </li>
              <li>
                <strong>Right to Disclosure of Information Sold and Right to Opt-Out:</strong> You have the right to know what 
                information of yours we have sold, and you have the right to opt-out of any sale of your information. We do not 
                sell any of your information. If you have any questions about these rights, please contact us  
                at <a href="mailto:support@apruvd.com">support@apruvd.com</a>.
              </li>
              <li>
                <strong>Right to Disclosure of Targeted Advertising and Right to Opt-Out (Colorado &amp; Virginia):</strong> 
                If you are a resident of Colorado or Virginia, you have the right to know what information of yours we have 
                processed for targeted advertising. If you have any questions about these rights or wish to opt-out of any 
                processing of your personal information as it relates to targeted advertising, please contact us  
                at <a href="mailto:support@apruvd.com">support@apruvd.com</a>.
              </li>
              <li>
                <strong>Right to Disclosure of Profiling (Colorado &amp; Virginia):</strong> If you are a resident of Colorado 
                or Virginia, you have the right to know what information of yours we have processed for the purposes of profiling 
                in furtherance of decisions that produce legal or similarly significant effects concerning the consumer. If you 
                have any questions about these rights or wish to opt-out of any processing of your personal information as it 
                relates to profiling, please contact us at <a href="mailto:support@apruvd.com">support@apruvd.com</a>.
              </li>
              <li>
                <strong>Colorado Right to Non-Discrimination:</strong> We do not and will not discriminate against you if you 
                exercise your rights under the CPA.
              </li>
              <li>
                <strong>Nevada Right to Non-Discrimination:</strong> We do not and will not discriminate against you if you 
                exercise your rights under the NPICICA.
              </li>
              <li>
                <strong>Virginia Right to Non-Discrimination:</strong> We do not and will not discriminate against you if you 
                exercise your rights under the VCDPA.
              </li>
            </ul>
            <p>
              When you contact us regarding any of your rights under the CPA, NPICICA, or VCDPA we will verify your identity 
              before we provide any information. If you have any questions or comments about your rights under the CPA, NPICICA, 
              or VCDPA please contact us at <a href="mailto:support@apruvd.com">support@apruvd.com</a>.
            </p>
            <h5>Your European Economic Area ("EEA") or Switzerland Rights</h5>
            <p>If you are based in the EEA or Switzerland you have other rights:</p>
            <p>
              You may send us an email at <a href="mailto:support@apruvd.com">support@apruvd.com</a> to request access to, 
              obtain copies of, correct, or delete any Personal Information that you have provided to us. Your email message 
              must include (i) your identifying information (including your IP address, if applicable), (ii) your contact 
              information, and (iii) information about the specific changes, deletions, or other action(s) you are requesting. 
              We require this information so we can determine which information in our control is your Personal Information and 
              complete the actions you requested. We may not accommodate a request to delete or change information if we believe 
              the deletion would violate any law or legal requirement.
            </p>
            <p>
              Any personal data that we collect is based upon your consent as detailed in this Privacy Notice. You have many 
              choices concerning the collection, use, and sharing of your data, including the ability to:
            </p>
            <ul>
              <li>
                <strong>Delete Data:</strong> You may request that we delete your Personal Information. Please note that we 
                cannot delete your Personal Information except by also deleting your user account.
              </li>
              <li>
                <strong>Change or Correct Data:</strong> You can also ask us to change, update, or fix your data in certain 
                cases, particularly if it's inaccurate. We may not accommodate a request to change information if we believe 
                the change would violate any law or legal requirement or cause the information to be incorrect.
              </li>
              <li>
                <strong>Object to, or Limit or Restrict, Use of Data:</strong> You may request that we do not use your Personal 
                Information, but keep in mind that this will terminate our ability to provide any Service(s) to you.
              </li>
              <li>
                <strong>Right to Access and/or Take Your Data:</strong> You can ask us for a copy of your Personal Information.
              </li>
            </ul>
            <h5>Notice to Residents of Countries outside the United States of America</h5>
            <p>
              Apruvd is headquartered in the United States of America. Personal Information may be accessed by us or transferred 
              to us in the United States or to our affiliates, business partners, or service providers elsewhere in the world. 
              By providing us with Personal Information, you consent to this transfer. We will protect the privacy and security 
              of Personal Information according to this Privacy Notice, regardless of where it is processed or stored.
            </p>
            <h4>6. CHILDREN'S ONLINE PRIVACY PROTECTION ACT</h4>
            <p>
              We do not knowingly collect information from minors. Our Site is intended for and directed towards adults. Our 
              Services are not directed to minors and we do not knowingly collect Personal Information from minors. Our Site is 
              not intended for children under 13 years of age. No one under age 13 may provide any personal information to or 
              on the Site. We do not knowingly collect personal information from children under 13. If you are under 13, do not 
              use or provide any information on this Site or on or through any of its features/register on the Site, make any 
              purchases through the Site, use any of the interactive or public comment features of this Site or provide any 
              information about yourself to us, including your name, address, telephone number, email address, or any screen 
              name or user name you may use. If we learn we have collected or received personal information from a child under 
              13 without verification of parental consent, we will delete that information. If you believe we might have any 
              information from or about a child under 13, please contact us at <a href="mailto:support@apruvd.com">support@apruvd.com</a> 
              or Compliance Department, 2999 North 44th Street, Suite 630 Phoenix, AZ 85018.
            </p>
            <h4>7. LOG FILES</h4>
            <p>
              As is true of most websites, we gather certain information automatically and store it in log files. This
              information includes internet protocol (IP) addresses, browser type, internet service provider (ISP),
              referring/exit pages, operating system, date/time stamp, and clickstream data. We use this information,
              which does not identify individual users, to analyze trends, to administer the Site, to track users'
              movements around the Site and to gather demographic information about our user base as a whole. We
              do not link this automatically-collected data to personally identifiable information. We track trends in
              users' usage and volume statistics to create a more efficient and usable Site and product offerings, and to
              determine areas of the Site or our Services that could be improved to enhance the user and customer
              experience. Log files are used on the Site, and in any link to the Site from an email.
            </p>
            <h4>8. MANAGE YOUR SECURITY SETTINGS</h4>
            <p>
              Some internet browsers have incorporated "Do Not Track" features. We respond to Do Not Track signals by 
              not tracking browser and URL information collected through a tracking pixel. However, for security purposes, 
              we do not respond to Do Not Track signals for information collected via server logs, such as user IP address 
              and firewall events.
            </p>
            <p>
              You may manage how your browser handles cookies and related technologies by adjusting its privacy
              and security settings. Browsers are different, so refer to instructions related to your browser to learn
              about cookie-related and other privacy and security settings that may be available. You can opt-out of
              being targeted by certain third-party advertising companies online at
              <a href="http://www.networkadvertising.org/choices/" target="_blank" rel="noreferrer"> http://www.networkadvertising.org/choices/</a>.
            </p>
            <p>
              You may manage how your mobile browser handles cookies and related technologies by adjusting your
              mobile device privacy and security settings. Please refer to instructions provided by your mobile service
              provider or the manufacturer of your device to learn how to adjust your settings.
            </p>
            <h4>8. CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION</h4>
            <p>
              We strive to provide you with choices regarding the Personal Information you provide to us. We have 
              created mechanisms to provide you with the following control over your information:
            </p>
            <ul>
              <li>
                <strong>Tracking Technologies and Advertising.</strong> You can set your browser to refuse all or some 
                browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash 
                cookie settings, visit the Flash player settings page on Adobe's website. If you disable or refuse cookies, 
                please note that some parts of this Site may then be inaccessible or not function properly.
              </li>
              <li>
                <strong>Disclosure of Your Information for Third-Party Advertising.</strong> We do not share your personal 
                information to any third-party for marketing or advertising purposes.
              </li>
              <li>
                <strong>Promotional Offers from the Company.</strong> If you do not wish to have your email address/contact 
                information used by us to promote our own or third parties' products or services, you can opt-out by sending 
                us an email stating your request to <a href="mailto:support@apruvd.com">support@apruvd.com</a>. If we have 
                sent you a promotional email, you may send us a return email asking to be omitted from future email 
                distributions. This opt out does not apply to information provided to us as a result of a product purchase, 
                warranty registration, product service experience, or other transactions.
              </li>
            </ul>
            <h4>9. COOKIES AND RELATED TECHNOLOGIES</h4>
            <p>When you use the Site, we collect certain information by automated electronic means, which may include:</p>
            <ul>
              <li>
                <strong>Browser Cookies –</strong> A cookie is a small file placed on the hard drive of your computer or 
                mobile device. You may refuse to accept browser cookies by activating the appropriate setting on your browser. 
                However, if you select this setting you may be unable to access certain parts of our Site. Unless you have 
                adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct 
                your browser to our Site.
              </li>
              <li>
                <strong>Flash Cookies –</strong> Certain features of our Site may use locally stored objects called Flash Cookies 
                to collect and store information about your preferences and navigation to, from, and on our Site. Flash Cookies 
                are not managed by the same browser settings as are used for Browser Cookies.
              </li>
              <li>
                <strong>Web Beacons –</strong> Our emails and certain pages of our Site may contain small electronic files known 
                as Web Beacons that permit the Company, for example, to count users who have visited those pages or opened an email 
                and for other related website statistics (for example, recording the popularity of certain website content and 
                verifying system and server integrity). Web Beacons are sometimes also referred to as clear gifs, pixel tags, and 
                single-pixel gifs.
              </li>
              <li>
                <strong>Session Cookies</strong> are temporary cookies used for various reasons, such as managing page views, 
                and are usually erased by your browser when you exit it.
              </li>
              <li>
                <strong>Persistent Cookies</strong> are used for a number of reasons, such as storing your preferences and 
                retrieving certain information you have previously provided. Persistent Cookies are stored on your devices after 
                you exit your browser.
              </li>
              <li>
                <strong>Analytics Cookies</strong> are cookies that allow us to count visits and traffic sources, so we can 
                measure and improve the performance of the website. They help us know which pages are the most and least popular 
                and see how visitors move around the website. All information these cookies collect is aggregated and therefore 
                anonymous. If you do not allow these cookies, we will not know when you have visited the website.
              </li>
              <li>
                <strong>Functional Cookies</strong> are cookies that enable the website to provide enhanced functionality and 
                personalization. They may be set by us or by third party providers whose services we have added to our pages. 
                If you do not allow these cookies then some or all of these services may not function properly.
              </li>
              <li>
                <strong>Targeting Cookies</strong> are cookies that may be set through the website by our advertising partners. 
                They can be used by these third parties to build a profile of your interests based on the browsing information 
                they collect from you, which includes uniquely identifying your browser and terminal equipment. If you do not 
                allow these cookies you will still see basic advertising on your browser that is generic and not based on your interests.
              </li>
              <li>
                Throughout the Company's Privacy Statement, we use the term "Cookies" to include Browser Cookies, Flash Cookies, 
                Web Beacons, Session Cookies, Persistent Cookies, Analytics Cookies, Functional Cookies, and Targeting Cookies.
              </li>
            </ul>
            <p>Our use of cookies, pixel tags and other technological measures includes:</p>
            <ul>
              <li>Collecting certain information about your equipment, browsing actions, and patterns;</li>
              <li>Speeding up your searches;</li>
              <li>
                Keeping details of your visits to our Services, including traffic data, location data, logs, and other 
                communication data and the resources that you access and use on the Services;
              </li>
              <li>Keeping track of your specified preferences allowing us to customize our Site according to your individual interest;</li>
              <li>Conducting research and diagnostics to improve our Services, including speeding up searches;</li>
              <li>Improving security;</li>
              <li>Measuring and analyzing the performance of our Services including estimating audience size and usage patterns;</li>
              <li>
                Delivering ads and other content relevant to your interests and providing advertisers with insights about the 
                people who see and interact with their ads and visit their websites;
              </li>
              <li>Estimating our audience size and usage patterns</li>
            </ul>
            <p>
              The information from cookies is also stored in web server logs, which are then transmitted back to the Site by your 
              computers or mobile devices. These servers and cookies are operated and managed by us and/or our service providers. 
              As you use the Site and Services, your browsers communicate with servers operated by the Company and/or our service 
              providers to coordinate and record the interactivity and fill your requests for services and information.
            </p>
            <p>Cookies are also used to collect certain information about your equipment, browsing actions, and patterns, including:</p>
            <ul>
              <li>
                Details of your visits to our Site, including traffic data, location data, logs, and other communication data and 
                the resources that you access and use on the Site; and
              </li>
              <li>Information about your computer and internet connection, including your IP address, operating system, and browser type.</li>
            </ul>
            <p>
              The information we collect is statistical and may include Personal Information, such as your IP address. Cookies help us 
              improve our Site and Services and help Us deliver better Site and Services, including by enabling us to:
            </p>
            <ul>
              <li>Estimate our audience size and usage patterns.</li>
              <li>Store information about your preferences, allowing us to customize our Site according to your individual interests.</li>
              <li>Speed up your searches.</li>
              <li>Recognize you when you return to our Site.</li>
            </ul>
            <h4>10. LINKS TO OTHER SITES</h4>
            <p>
              This Site may contain links to other sites that are not owned or controlled by Apruvd. Please be aware
              that we are not responsible for the privacy practices of such other sites. We encourage you to be aware
              when you leave our site and to read the privacy statements of each and every website that collects
              personally identifiable information. This Privacy Notice applies only to information collected by this
              Site.
            </p>
            <h4>11. ACCESSING AND CORRECTING PERSONALLY IDENTIFIABLE INFORMATION</h4>
            <p>
              If you want to update or delete your contact information, terminate your subscription, or have other
              questions or requests, please contact us at <a href="mailto:support@apruvd.com">support@apruvd.com</a>. 
              While we are ready to assist you in managing your subscriptions, and closing your account, we cannot 
              always delete records of past interactions and transactions. For example, we are required to retain 
              records relating to previous purchases for financial reporting and compliance reasons.
            </p>
            <h4>12. SECURITY</h4>
            <p>
              We follow generally accepted industry standards to protect Personal Information, including your email
              address, submitted to us, both during transmission and once we receive it. However, no method of
              transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while we
              strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee
              its absolute security. Any transmission of personal information is at your own risk. We are not
              responsible for circumvention of any privacy settings or security measures contained on the Site.
            </p>
            <p>
              All information you provide to us is stored on our secure servers behind firewalls. We utilize Amazon
              Web Services and industry standard Best Practices. Any payment transactions will be encrypted using
              SSL technology.
            </p>
            <p>
              The safety and security of your information also depends on you. Where we have given you (or where
              you have chosen) a password for access to certain parts of our Website, you are responsible for keeping
              this password confidential. We ask you not to share your password with anyone.
            </p>
            <h4>13. CHANGES IN THIS PRIVACY STATEMENT</h4>
            <p>
              If we decide to change our Privacy Notice, we will post those changes to this Privacy Notice and other
              places we deem appropriate so that you are aware of what information we collect, how we use it, and
              under what circumstances, if any, we disclose it. We reserve the right to modify this Privacy Notice at
              any time, so please review it frequently.
            </p>
            <h4>&copy; 2018-2022 Apruvd, LLC</h4>
          </Col>
        </Row>
        <Footer />
      </div>
    )
  }
}

export default PrivacyPolicy
