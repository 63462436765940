import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import 'styles/Contact.css'
import ContactForm from 'components/ContactForm'
import PageHeader from 'components/PageHeader'
import Footer from 'components/Footer'
import ScrollLink from 'components/ScrollLink'

import RightArrow from 'images/right-arrow.png'

class Contact extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  constructor({ match, ...props }) {
    super()
    this.state = {
      path: match.path
    }
  }
  render() {
    return (
      <div className="Contact">
        <PageHeader />
        <article className="lets-connect">
          <Row>
            <Col md={6} className="connect">
              <h1>Let's Connect<span className="green">.</span></h1>
              <p>Dealing with chargebacks or false declines? Looking for a higher quality of fraud analysis? Scaling your business internationally?</p>
              <h3 className="right"><span className="connect green">We can help<img src={RightArrow} alt="We can help" /></span></h3>
            </Col>
            <Col md={6} className="contact-form">
              <h3><span className="green">Next-Level Fraud Protection </span><em>Starts Here.</em></h3>
              <ContactForm path={this.state.path} />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="center more">
              <ScrollLink to="#contact-info" offset={-4}>Give Us A Call</ScrollLink>
            </Col>
          </Row>
        </article>
        <article id="contact-info" className="contact-info">
          <Row>
            <Col md={6}>
              <h4 className="green">Hi, great to meet you</h4>
              <p>We help businesses like yours grow faster and ensure the best shopping experience for your customers.</p>
            </Col>
            <Col md={6} className="right">
              <h3>Offices<span className="green">.</span></h3>
              <hr />
              <h6>2999 North 44th Street</h6>
              <h6>Suite 630</h6>
              <h6>Phoenix, AZ 85018</h6>
              <br />
              <h6>P. (480) 477-9150</h6>
              <h6>info@apruvd.com</h6>
            </Col>
          </Row>
        </article>
        <Footer />
      </div>
    )
  }
}

export default Contact
