import React, { Component } from 'react'
import { Button, Row, Col } from 'react-bootstrap'

class ContactForm extends Component {
  constructor({ ...props }) {
    super()
    this.state = {
      path: props.path,
      submitted: false,
      showFeedback: false,
      email: '',
      phone: '',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value})
  }

  handleSubmit(e) {
    // TODO: email/phone validation
    if (this.state.email === '' && this.state.phone === '') {
      e.preventDefault()
      this.setState({[e.target.name]: e.target.value})
      this.setState({showFeedback: true})
    }
    else {
      this.setState({showFeedback: false})
    }
  }

  render() {
    if (this.state.path === "/success" || this.state.submitted) {
      return (
        <div className="ContactSuccessMessage">
          <p>
            Thanks! You will hear from us soon. 
            Check out our <a href="https://www.linkedin.com/company/apruvd-llc/" target="_blank" rel="noreferrer">LinkedIn</a> page 
            for current information or feel free to continue browsing the site.
          </p>
        </div>
      )
    } 
    else {  
      return (
        <div className="ContactForm">
          <div className="FormFeedback alert alert-danger" style={{visibility: this.state.showFeedback ? 'visible' : 'hidden' }}>
            <p>* A valid Phone Number or Email Address is required</p>
          </div>
          <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST" onSubmit={this.handleSubmit}>
            <input type="hidden" name="oid" value="00D2E000001Hqx6" />
            <input type="hidden" name="retURL" value="https://apruvd.com/success" />
            <input type="hidden" name="lead_source" value="Website Contact Request" />
            <Row>
              <Col md={6}>
                <input name="first_name" tabIndex="1" type="text" placeholder="First Name" required />
                <input name="email" tabIndex="3" type="text" placeholder="Email" value={this.state.email} onChange={this.handleChange} />
                <input name="phone" tabIndex="5" type="text" placeholder="Phone" value={this.state.phone} onChange={this.handleChange} />
              </Col>
              <Col md={6}>
                <input name="last_name" tabIndex="2" type="text" placeholder="Last Name" />
                <input name="company" tabIndex="4" type="text" placeholder="Business/Organization" />
                <input name="notes" tabIndex="6" type="text" placeholder="Interested In" />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="center">
                <Button type="submit">Get in Touch</Button>
              </Col>
            </Row>
          </form>
        </div>
      )
    }
  }
}

export default ContactForm
