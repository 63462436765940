import React, { Component } from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { genericHashLink } from 'react-router-hash-link'

import 'styles/Services.css'
import CTA from 'components/CTA'
import PageHeader from 'components/PageHeader'
import Footer from 'components/Footer'
import Icon from 'components/Icon'
import LinkButton from 'components/LinkButton'
import ScrollLink from 'components/ScrollLink'

import Quote from 'images/quote.png'
import ShieldImg from 'images/apruvd-shield.png'
import CogImg from 'images/apruvd-cogs.png'
import SearchImg from 'images/apruvd-search.png'
import ChatImg from 'images/apruvd-chat.png'
import ServicesCards from 'images/services-cards.png'

class Services extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const HashButton = genericHashLink(Button)
    const scrollOffsetPercentage = (el, offset) => {
      const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
      const yOffsetPercent = offset;
      const yOffsetDiff = yCoordinate * (yOffsetPercent * .01)
      window.scrollTo({ top: yCoordinate + yOffsetDiff, behavior: 'smooth' })
    }
    return (
      <div className="Services">
        <PageHeader />
        <article className="welcome">
          <Row>
            <Col md={12} className="tagline">
              <h4><span className="green">What We Do</span></h4>
              <h1>
                <span className="green">Detecting More</span> Than Just Fraud<span className="green">.</span>
              </h1>
            </Col>
          </Row>
          <Row>
            <Col md={10} className="tagline">
              <p>We combine the best in automation and manual review to accurately identify legitimate orders and <strong><em>approve all good customers.</em></strong></p>
            </Col>
          </Row>
          <Row>
            <Col md={8}></Col>
            <Col md={4} className="slogan">
              <CTA to="/plans" title="Find your plan" text="Request a Quote" />
            </Col>
          </Row>
          <Row className="lm">
            <Col md={12} className="center more">
              <ScrollLink to="#guarantee" offset={-10}>Learn More</ScrollLink>
            </Col>
          </Row>
          <Row id="guarantee">
            <Col md={12} className="center fp-guarantee">
              <h2><span className="green">Fraud Protection Guaranteed</span> To Help Your Business Grow<span className="green">.</span></h2>
              <p>We help eCommerce businesses approve more legitimate customers and eliminate fraud-related chargebacks, for good.</p>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="center fp-buttons">
              <ul>
                <li><HashButton smooth to="#detect" scroll={el => scrollOffsetPercentage(el, -4.5)}>Detect<span className="green">.</span></HashButton></li>
                <li><HashButton smooth to="#protect" scroll={el => scrollOffsetPercentage(el, -3)}>Protect<span className="green">.</span></HashButton></li>
                <li><HashButton smooth to="#approve" scroll={el => scrollOffsetPercentage(el, -2.15)}>Approve<span className="green">.</span></HashButton></li>
              </ul>
            </Col>
          </Row>
          {/* <Row>
            <Col md={12} className="center more">
              <ScrollLink to="#fraud-protection" offset={-8}>Learn How</ScrollLink>
            </Col>
          </Row> */}
          <Row id="fraud-protection" className="fp-modal">
            <Col md={1}>
            </Col>
            <Col md={6}>
              <h2><span className="green">Not Your Average</span> Fraud Protection<span className="green">.</span></h2>
              <p>
                Apruvd provides guaranteed fraud protection to eCommerce businesses. Sometimes referred to as Decision-As-A-Service, we review online orders
                and return a decision, either <strong><em>Approved</em></strong> or <strong><em>Declined</em></strong>. Approved orders are guaranteed against
                fraud risk, and declines are free of charge.
              </p>
              <div className="widget">
                <img src={ServicesCards} alt="Services" />
              </div>
              <p className="caption">We tailor our solutions to your needs, reviewing <strong>Declines, All Orders</strong>, or a <strong>Custom Mix</strong></p>
              <Link to="/plans">Find a plan that works for you</Link>
            </Col>
          </Row>
        </article>
        <article className="features">
          <Row>
            <Col md={12} className="center">
              <h3>Features<span className="green">.</span></h3>
              <p>Expertise, Quality, and Care.</p>
            </Col>
          </Row>
          <Row className="featureset">
            <Col md={3} xs={6} className="center">
              <div className="icon"><img src={ShieldImg} alt="Chargeback Guarantee" /></div>
              <h3>Chargeback Guarantee</h3>
              <p>Risk-Free, Zero-Chargeback Fraud Protection</p>
            </Col>
            <Col md={3} xs={6} className="center">
              <div className="icon"><img src={CogImg} alt="Expert Decisioning" /></div>
              <h3>Expert Decisioning</h3>
              <p>Best-in-Class Machine Learning and Expert Manual Review</p>
            </Col>
            <Col md={3} xs={6} className="center">
              <div className="icon"><img src={SearchImg} alt="Data Intelligence" /></div>
              <h3>Data &amp; Device Intelligence</h3>
              <p>Review of High-Risk, Escalated, and Complex Orders</p>
            </Col>
            <Col md={3} xs={6} className="center">
              <div className="icon"><img src={ChatImg} alt="Customer Care" /></div>
              <h3>Customer Care</h3>
              <p>Superior Customer Service &amp; Support</p>
            </Col>
          </Row>
        </article>
        <article className="testimonial">
          <Row>
            <Col className="center" md={12}>
              <img className="icon" src={Quote} alt="Quote" />
              <blockquote>
                We quickly realized the monetary value Apruvd brought us, in addition to the supportive and quick action they take daily to support our fraud investigation team.
              </blockquote>
              <p>Brian Brewer<br />Business Analyst at Sports Endeavors</p>
              <img className="icon" src={Quote} alt="Quote" />
            </Col>
          </Row>
        </article>
        <article id="detect" className="detect-revenue">
          <Row>
            <Col md={1}>
              <h3>Detect<span className="green">.</span></h3>
            </Col>
            <Col md={7}>
              <h2 className="purple">Don't Just Prevent Fraud.</h2>
              <h2><em>Detect Revenue<span className="green">.</span></em></h2>
              <p>Combining big data, modern fraud technologies, and deep linking abilities with expert human analysis we accurately identify <em>all legitimate customers</em>.</p>
              <h4 className="green">What We Do</h4>
              <ul>
                <li><Icon i="PurpArrow" />Approve More Orders</li>
                <li><Icon i="PurpCancel" />Eliminate Chargebacks</li>
                <li><Icon i="PurpBar" />Convert Declines into Revenue</li>
              </ul>
            </Col>
            <Col className="center" md={4}>
              <div className="green-bubble">
                <h3 className="green">Revenue Detection</h3>
                <p>More Approved Orders</p>
              </div>
              <div className="purple-bubble">
                <h3>Fraud Prevention</h3>
                <p>100% Guaranteed</p>
              </div>
            </Col>
          </Row>
          <Row className="see">
            <Col md={1}>
            </Col>
            <Col className="center" md={11}>
              <h2>See <em>All</em> Your Customers<span className="green">.</span></h2>
              <p>Fraud can look like a lot of different things.</p>
              <p>With Apruvd, only decline fraudsters, <em>never good customers. <strong>Guaranteed.</strong></em></p>
            </Col>
          </Row>
          <Row className="swarm">
            <Col md={7}>
              <ul>
                <li className="ai">Artificial Intelligence</li>
                <li className="hc">Happier Customers</li>
                <li className="int">Human Intelligence</li>
                <li className="oth purple">Others</li>
                <li className="us green">Apruvd</li>
                <li className="orders">More Approved Orders</li>
                <li className="rev">Increased Revenue</li>
                <li className="data">Rich Data &amp; AI</li>
              </ul>
              <div id="apruvd-assurance"></div>
            </Col>
            <Col className="center" md={4}>
              <h4>Leave no good customers behind</h4>
              <h3 className="purple">At least 50% of your declined orders are actually legitimate customers.</h3>
              <p>Are your fraud tools preventing more than just fraud?</p>
              <LinkButton to="/contact">Find Out Now</LinkButton>
            </Col>
          </Row>
        </article>
        <article id="protect" className="protect">
          <Row>
            <Col md={1}>
              <h3>Protect<span className="green">.</span></h3>
            </Col>
            <Col md={6}>
              <h4>100% Guaranteed <span className="green">Fraud Protection</span></h4>
              <h3>Better Protection Starts With <span className="green">Better Detection.</span></h3>
              <p>
                We work hard to guarantee the highest ROI in the industry. With <strong>Apruvd</strong>Assurance&trade; experience zero risk, 
                zero chargeback costs, and 100% peace of mind knowing no good customers are left behind.
              </p>
              <LinkButton to="/plans">Detect Revenue Now</LinkButton>
            </Col>
            <Col md={5}>
              <div className="bubbled">
                <Icon i="Squiggle" />
                <h3><strong>Apruvd</strong><em>Assurance&trade;</em></h3>
                <h4><strong>We approve it.</strong><em> We guarantee it.</em></h4>
              </div>
              <h5>Decision Smarter. Approve More Orders. <strong><em>Guaranteed.</em></strong></h5>
              <Row>
                <Col className="center" xs={6}>
                  <ul>
                    <li>Zero Chargebacks</li>
                    <li>100% Peace Of Mind</li>
                    <li>No Integration Rules</li>
                  </ul>
                </Col>
                <Col className="center" xs={6}>
                  <ul>
                    <li>No Rules Management</li>
                    <li>Only Pay For Approvals</li>
                    <li>Lightweight &amp; Layerable</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={1}>
            </Col>
            <Col md={10}>
              <h2><span className="purple">Never Decline</span> a Good Customer Again<span className="green">.</span></h2>
              <h3>Protect your business <strong><em>and</em></strong> customers' satisfaction.</h3>
            </Col>
          </Row>
          <Row className="dog-ski">
            <Col md={1}>
            </Col>
            <Col md={5}>
              <p>At Apruvd, our unique approach enables the detection of <strong><em>all good orders</em></strong> and eliminates false-declines and unhappy shoppers.</p>
              <ul>
                <li><Icon i="Arrow" />Improve Shopper Satisfaction</li>
                <li><Icon i="Arrow" />Eliminate Customer Insult &amp; Friction</li>
                <li><Icon i="Arrow" />Increase Lifetime Customers</li>
              </ul>
            </Col>
          </Row>
        </article>
        <article id="approve" className="approve-more">
          <Row>
            <Col md={1}>
              <h3>Approve<span className="green">.</span></h3>
            </Col>
            <Col md={5}>
              <h4>Capture more sales <span className="green">with Apruvd</span></h4>
              <h2><span className="green">Better Detection</span>. Better Results<span className="green">.</span></h2>
              <h3>Apruvd by the numbers<span className="green">.</span></h3>
            </Col>
            <Col className="right" md={6}>
              <p>We alleviate the burden of manual review, making sense of anomalous orders <em>and</em> finding revenue in your declines.</p>
              <LinkButton to="/contact">Get Started Today</LinkButton>
            </Col>
          </Row>
          <Row className="numbers">
            <Col md={1}>
            </Col>
            <Col md={11}>
              <Row>
                <Col className="center" md={3} xs={6}>
                  <div className="tile">
                    <h5 className="above">More Than</h5>
                    <h2><strong>220</strong>M</h2>
                    <p>Total revenue recovered from merchant declines</p>
                  </div>
                </Col>
                <Col className="center" md={3} xs={6}>
                  <div className="tile">
                    <h2 className="green"><strong>6</strong></h2>
                    <h5>Minutes or Less</h5>
                    <p>90% of decisions turned around in 6 minutes or less</p>
                    <p className="mini"><em><strong>(80% of orders within seconds)</strong></em></p>
                  </div>
                </Col>
                <Col className="center" md={3} xs={6}>
                  <div className="tile">
                    <h2><strong>1</strong></h2>
                    <h5>Day or Less</h5>
                    <p>On-board your team and have Apruvd review and decision orders same-day</p>
                  </div>
                </Col>
                <Col className="center" md={3} xs={6}>
                  <div className="tile">
                    <h2 className="green"><strong>100</strong>%</h2>
                    <h5>Chargeback Coverage</h5>
                    <p>With <em><strong>ApruvdAssurance</strong></em>&trade; get 100% guaranteed chargeback coverage on all approved orders</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </article>
        <Footer />
      </div>
    )
  }
}

export default Services
