import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import PageHeader from 'components/PageHeader'
import Footer from 'components/Footer'

class Careers extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="Careers">
        <PageHeader />
        <Row className="wot">
          <Col md={12}>
            <h3>Careers at Apruvd</h3>
            <p>
              Apruvd is not currently accepting resumes for the Fraud Analyst position. Please check back later for updates.
            </p>
          </Col>
        </Row>
        <Footer />
      </div>
    )
  }
}

export default Careers
