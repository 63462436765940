import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import animate from 'animateplus'

import 'styles/Home.css'
import LinkButton from 'components/LinkButton'
import ScrollLink from 'components/ScrollLink'
import CTA from 'components/CTA'
import Icon from 'components/Icon'
import BulletPointSVG from 'components/BulletPointSVG'
import PageHeader from 'components/PageHeader'
import Footer from 'components/Footer'

import DownArrow from 'images/down-arrow.png'
import BarDarkIcon from 'images/bar-dark-icon.png'
import SearchIcon from 'images/search-icon.png'
import ShieldLock from 'images/shield-lock.png'
import Caret from 'images/caret.png'
import Quote from 'images/quote.png'
import AllOrdersImage from 'images/all-orders.png'
import CustomMixImage from 'images/custom-mix.png'
import DeclinesOnlyImage from 'images/declines-only.png'
import RightArrow from 'images/right-arrow.png'

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    const blinkField = [
      {id: ".bpsvg-1-shadow", opacityStart: 1.0, opacityEnd: 0.65, duration: 750},
      {id: ".bpsvg-2-shadow", opacityStart: 0.8, opacityEnd: 0.45, duration: 500},
      {id: ".bpsvg-3-shadow", opacityStart: 1.0, opacityEnd: 0.55, duration: 600},
      {id: ".bpsvg-4-shadow", opacityStart: 1.0, opacityEnd: 0.35, duration: 100},
      {id: ".bpsvg-5-shadow", opacityStart: 0.7, opacityEnd: 0.40, duration: 750},
      {id: ".bpsvg-6-shadow", opacityStart: 1.0, opacityEnd: 0.55, duration: 150}
    ]

    blinkField.map( bullet => (
      animate({
        elements: bullet.id,
        easing: "in-out-cubic",
        duration: 1000 + Math.floor(Math.random() * (bullet.duration + 750)),
        delay: Math.floor(Math.random() * 1000),
        loop: true,
        direction: "alternate",
        opacity: [bullet.opacityStart, bullet.opacityEnd]
      })
    ))
  }
  
  render() {
    return (
      <div className="Home">
        <PageHeader />
        <article className="welcome">
          <Row>
            <Col md={8} className="tagline">
              <h4><span className="green">Welcome to</span> Apruvd</h4>
              <h1>
                <span className="green">Better Fraud Protection. </span>Guaranteed<span className="green">.</span>
              </h1>
            </Col>
            <Col md={4} className="slogan">
              <p>Helping eCommerce businesses say goodbye to chargebacks and false declines and hello to happier customers.</p>
              <CTA to="/contact" title="Find your plan" text="Request A Quote" />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="center more">
              <ScrollLink to="#guarantee" offset={-3.75}>More About Us</ScrollLink>
            </Col>
          </Row>
          <Row id="guarantee">
            <Col md={12} className="center guarantee">
              <h4 className="green">We Are Apruvd</h4>
              <h2><span className="green">100% Guaranteed </span>Fraud Protection.</h2>
              <p>Real reviewers, real data, and the assurance you need for <em><strong>real results</strong></em>.</p>
            </Col>
            <Col md={12} className="right we-are-apruvd">
              <h5><span className="green"><em>Decision Smarter</em> with Apruvd.</span></h5>
              <p>Apruvd is comprehensive fraud protection for your business &#8212; Approving more legitimate customers than the competition, guaranteed.</p>
              <Link to="/about">Get To Know Us <img className="right-arrow" src={RightArrow} alt="Get To Know Us" /></Link>
            </Col>
          </Row>
        </article>
        <article className="happy-customers">
          <Row>
            <Col md={6}>
              <h4 className="small"><span className="green">Next-level</span> fraud protection</h4>
              <h3><span className="green">Approve <em>All</em></span> Good Customers<span className="green">.</span></h3>
              <p>Standard fraud tools are preventing more than just fraud. With Apruvd, detect and approve <strong><em>all </em></strong> legitimate customers.</p>
              <Row className="customer-icons">
                <Col xs={4} className="center">
                  <Icon i="BarChart" />
                  <span>Increase Sales</span>
                </Col>
                <Col xs={4} className="center">
                  <Icon i="People" />
                  <span>Happier Customers</span>
                </Col>
                <Col xs={4} className="center">
                   <Icon i="ShieldLock" />
                  <span>100% Guaranteed</span>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <LinkButton to="/about">Our Difference</LinkButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </article>
        <article className="big-circle">
          <Row>
            <Col md={7} className="center">
              <h4>Leave the tough decisions to us</h4>
              <h3><span className="green">Chargebacks &amp; False Declines</span> Should Not Be a Cost of Doing Business.</h3>
              <p>Keep your business safe from fraud <strong><em>and</em></strong> eliminate false declines and chargebacks, <strong><em>for good.</em></strong></p>
            </Col>
            <Col md={5} className="center">
              <h4>We see things others don't, <span className="green">in ways others can't.</span></h4>
              <ul className="left">
                <li><img className="icon" src={SearchIcon} alt="Search Icon" />Better Detection</li>
                <li><img className="icon" src={ShieldLock} alt="Shield Icon" />Better Protection</li>
                <li><img className="icon" src={BarDarkIcon} alt="Stock Icon" />Better Results</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              &nbsp;
            </Col>
            <Col md={4} className="center">
              <LinkButton className="medium" to="/about">Learn How</LinkButton>
            </Col>
          </Row>
        </article>
        <article className="all-about-customers center">
          <Row>
            <Col md={6}>
              &nbsp;
            </Col>
            <Col md={6}>
              <h2><span className="green">Every Customer Counts.</span></h2>
              <h3>We make sure their purchases do, too.</h3>
              <p>Every day, good customers get declined — <strong><em>not with Apruvd</em></strong>.
                We know the value of a lifetime customer, and we work hard to ensure the very 
                best shopping experience for your customers and the highest ROI for your business.
              </p>
              <LinkButton to="/about">How We Work</LinkButton>
              <h5>Are Your Fraud Tools <em><strong>Preventing More Than Just Fraud?</strong></em></h5>
              <Link to="/contact"><h4>Find Out Now</h4><img className="down-arrow" src={DownArrow} alt="More about us" /></Link>
            </Col>
          </Row>
        </article>
        <article className="what-we-do">
          <Row>
            <Col md={6}>
              <ul>
                <li className="abt-one">
                  <BulletPointSVG className="bpsvg-1" />
                  <span>100% Guaranteed Fraud Protection</span>
                </li>
                <li className="abt-two">
                  <BulletPointSVG className="bpsvg-2" />
                  <span>Approve 50% Of Orders Declined By Other Systems</span>
                </li>
                <li className="abt-three">
                  <BulletPointSVG className="bpsvg-3" />
                  <span>Zero Risk</span>
                </li>
                <li className="abt-four">
                  <BulletPointSVG className="bpsvg-4" />
                  <span>Eliminate Chargebacks</span>
                </li>
                <li className="abt-five">
                  <BulletPointSVG className="bpsvg-5" />
                  <span>No Integration Required</span>
                </li>
                <li className="abt-six">
                  <BulletPointSVG className="bpsvg-6" />
                  <span>Expert Manual Review</span>
                </li>
              </ul>
            </Col>
            <Col md={6} className="right">
              <h4><span className="green">Capture more sales</span> with Apruvd</h4>
              <h2><span className="green">Leave No Good </span>Customers Behind<span className="green">.</span></h2>
              <p>More Approved Orders. 100% Happy Customers.</p>
              <LinkButton className="medium" to="/services">What We Do</LinkButton>
            </Col>
          </Row>
        </article>
        <article className="value">
          <Row>
            <Col md={5} className="center">
              <h3>Our value is seen in <em>your</em> results.</h3>
              <LinkButton to="/customers">Our Customers' Wins</LinkButton>
            </Col>
            <Col md={7} className="center">
              <h4>Better Decisioning. <span className="green">Better Results.</span></h4>
              <Row>
                <Col xs={6}>
                  <blockquote>
                    <span><img className="icon" src={Caret} alt="Up Arrow" /><span>50-90%</span></span>
                    <span className="green"><p>Approved orders that other systems declined</p></span>
                  </blockquote>
                </Col>
                <Col xs={6}>
                  <blockquote>
                    <span><img className="icon-flipped" src={Caret} alt="Down Arrow" /><span>$0.00</span></span>
                    <span className="green"><p>Fraudulent Chargeback Dollars</p></span>
                  </blockquote>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <blockquote>
                    <span><img className="icon" src={Caret} alt="Up Arrow" /><span>$220M+</span></span>
                    <span className="green"><p>Total Revenue Recovered From Declines</p></span>
                  </blockquote>
                </Col>
                <Col xs={6}>
                  <blockquote>
                    <span><img className="icon-flipped" src={Caret} alt="Down Arrow" /><span>.017%</span></span>
                    <span className="green"><p>Chargeback Rate <em>(Complete Coverage Customers)</em></p></span>
                  </blockquote>
                </Col>
              </Row>
            </Col>
          </Row>
        </article>
        <article className="plans">
          <Row>
            <Col md={8}>
              <h4 className="small">Plans Tailored <span className="green">to Fit Your Needs</span></h4>
              <h2><span className="green">No System's Complete</span><br />Without Apruvd.</h2>
              <p>Layer Apruvd into your existing system, or fully integrate with us. We can handle all orders, just declines, or a custom mix — we fit into your business, not the other way around.</p>
              <Row>
                <Col md={4} xs={6} className="center">
                  <img className="icon" src={DeclinesOnlyImage} alt="" />
                  <h3>Apruvd<strong>Revive</strong>&trade;</h3>
                  <p className="right"><a href="/plans#plans-list">Learn More <img className="right-arrow" src={RightArrow} alt="Learn More" /></a></p>
                </Col>
                <Col md={4} xs={6} className="center">
                  <img className="icon" src={CustomMixImage} alt="" />
                  <h3>Apruvd<strong>Custom</strong>&trade;</h3>
                  <p className="right"><a href="/plans#plans-list">Learn More <img className="right-arrow" src={RightArrow} alt="Learn More" /></a></p>
                </Col>
                <Col md={4} xs={12} className="center">
                  <img className="icon" src={AllOrdersImage} alt="" />
                  <h3>Apruvd<strong>Complete</strong>&trade;</h3>
                  <p className="right"><a href="/plans#plans-list">Learn More <img className="right-arrow" src={RightArrow} alt="Learn More" /></a></p>
                </Col>
              </Row>
              <Row className="find-plan">
                <Col md={12} className="center">
                <LinkButton to="/plans">Find Your Plan</LinkButton>
                  </Col>
              </Row>
            </Col>
          </Row>
          <Row className="big-quote">
            <Col md={6} className="right">
              <h3><span className="green">More than a solution,</span> we're a true partner<span className="green">.</span></h3>
              <LinkButton to="/customers">Our Customers</LinkButton>
            </Col>
            <Col className="quote" md={5}>
              <img className="icon" src={Quote} alt="Quote" />
              <p className="center">Our internal fraud team had the basic tools to screen orders, but nothing that could compare to Apruvd.</p>
              <p className="small right">Steve Ber</p>
              <p className="small right">VP of Finance at Power Equipment Direct</p>
              <img className="icon" src={Quote} alt="Quote" />
            </Col>
          </Row>
        </article>
        <article className="get-started">
          <Row>
            <Col className="center">
              <h2>Looking for next-level fraud protection?</h2>
              <LinkButton to="/contact">Get Started</LinkButton>
            </Col>
          </Row>
        </article>
        <Footer />
      </div>
    )
  }
}

export default Home
