import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import 'styles/About.css'
import CTA from 'components/CTA'
import LinkButton from 'components/LinkButton'
import ScrollLink from 'components/ScrollLink'
import PageHeader from 'components/PageHeader'
import Footer from 'components/Footer'
import Icon from 'components/Icon'

class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="About">
        <PageHeader />
        <article className="welcome">
          <Row>
            <Col md={8} className="tagline">
              <h4><span className="green">Who we are</span></h4>
              <h1>
                <span className="green">Detecting the Truth</span> in Every Transaction<span className="green">.</span>
              </h1>
            </Col>
            <Col md={4} className="slogan">
              <CTA to="/contact" title="Find your plan" text="Request A Quote" />
              <p>Expert decisioning and guaranteed fraud protection for your business.</p>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="center more">
              <ScrollLink to="#get-to-know-us" offset={-13}>Get To Know Us</ScrollLink>
            </Col>
          </Row>
          <Row id="get-to-know-us" className="how-we-work">
            <Col md={12} className="center">
              <h4 className="green">100% Guaranteed Chargeback Coverage</h4>
              <h2><span className="green">Next-Level </span>Fraud Protection</h2>
              <h5>Helping eCommerce businesses around the world turn declines into revenue and eliminate unnecessary friction and chargebacks.</h5>
              {/* <p>Learn why we have the happiest customers in the business</p> */}
            </Col>
            {/* <Col md={12} className="center">
              <ScrollLink to="#connect-the-dots" offset={-6}></ScrollLink>
            </Col> */}
          </Row>
        </article>
        <article id="connect-the-dots" className="connect-dots">
          <h4 className="green">Connecting <strong><em>all</em></strong> the dots</h4>
          <Row>
            <Col md={6}>
              <h2><span className="green">We Make Sense of Orders </span>That Don't<span className="green">.</span></h2>
              <p>When legacy tools, calculated risk scores, and automated declines aren't enough - <strong><em>it's time for Apruvd.</em></strong></p>
            </Col>
            <Col className="right" md={6}>
              <h3>Legacy fraud tools may be good at what <strong><em>they do.</em></strong></h3>
              <h3 className="green">They just can't do <strong><em>what we do.</em></strong></h3>
              <p>Ready For Next-Level Protection?</p>
              <LinkButton className="smaller" to="/contact">Get Started Today</LinkButton>
            </Col>
          </Row>
        </article>
        <article className="fraud-services">
          <Row>
            <Col md={6}>
              <h3>Not all order review services are created equal &mdash; we don't just prevent fraud, <span className="green"><strong><em>we detect revenue</em></strong></span>.</h3>
              <p>The best in automation and manual review &mdash; we ensure the protection of both your business <strong>and</strong> customer satisfaction.</p>
            </Col>
            <Col md={1}>
            </Col>
            <Col md={5} className="right">
              <h3>Services</h3>
              <ul>
                <li>Accurate Fraud Detection<Icon i="ManifyingGlass" /></li>
                <li>Best-in-Class Machine &amp; Manual Review Specialists<Icon i="Cog" /></li>
                <li>100% Guaranteed Decisioning on All Orders<Icon i="ThickSquiggle" /></li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="center">
              <p>Don't settle for suboptimal approval rates and painful chargebacks.</p>
              <LinkButton className="smaller" to="/services">Learn How</LinkButton>
            </Col>
          </Row>
        </article>
        <article className="fraud-review">
          <Row>
            <Col md={12}>
              <h4>The human side of fraud protection</h4>
              <h2><span className="green">We Are </span>Decisioning Experts<span className="green">.</span></h2>
              <p className="purple">Pioneers in the use of analytics and technology, we provide valuable and accurate decisioning for eCommerce businesses helping you grow faster and build stronger connections with <em>all</em> your customers.</p>
            </Col>
          </Row>
          <Row className="fraud-protect">
            <Col md={7}>
              <h2><span className="green">Best of machine and manual review</span>.</h2>
              <p>Apruvd team members are experts at reviewing and researching orders — eliminating your frustration, lost time, and revenue. These guaranteed results save you priceless customers, time, and money.</p>
              <a href="/customers">See what our customers have to say <Icon i="Arrow" /></a>
            </Col>
            <Col md={5} className="center">
              <p>Losing Money to Chargebacks?</p>
              <p>Too Many False Declines?</p>
              <p>Spending Time on Fraud Review?</p>
              <h4>It's Time For Apruvd.</h4>
              <LinkButton className="smaller" to="/contact">Get Started</LinkButton>
            </Col>
          </Row>
          <Row className="different">
            <Col md={1}>
            </Col>
            <Col xs={6}>
              <h2>Different <span className="green">By Design.</span></h2>
            </Col>
            <Col className="right" md={5}>
              <h3>Dedication<span className="green">.</span> Innovation<span className="green">.</span></h3>
              <h3>Honesty<span className="green">.</span> Integrity<span className="green">.</span></h3>
              <h3>Commitment<span className="green">.</span></h3>
              <h3>Compassion<span className="green">.</span> People<span className="green">.</span></h3>
            </Col>
          </Row>
        </article>
        <article className="what-we-bring">
          <Row>
            <Col md={3} xs={6} className="center">
              <Icon i="AbsStack" />
              <h3>Customized &amp; Flexible</h3>
              <p>Not a one-size-fits-all approach - we tailor our solutions to your business, allowing us to grow and scale with your needs.</p>
            </Col>
            <Col md={3} xs={6} className="center">
              <Icon i="AbsDrone" />
              <h3>A True Partner</h3>
              <p>We believe in collaboration and transparency, providing you with detailed feedback and essential data to drive the best results.</p>
            </Col>
            <Col md={3} xs={6} className="center">
              <Icon i="AbsIL" />
              <h3>Mind &amp; Machine</h3>
              <p>
                Robots, Machine Learning, and Artificial Intelligence are essential in fighting fraud. We believe in the powerful synergy of automation and
                expertly trained human intelligence to approve orders that may otherwise be declined. Humans provide an adaptability and insight that machines can miss.
              </p>
            </Col>
            <Col md={3} xs={6} className="center">
              <Icon i="AbsBar" />
              <h3>See the Good&trade;</h3>
              <p>
                We stand behind every decision we make for your business - understanding the impact an inaccurate decision can have on your business, whether that's 
                a chargeback or a false decline. That's why we offer a chargeback guarantee called <strong>ApruvdAssurance</strong>&trade; on every decision. We
                understand the impact a false decline can have on the value of losing a lifetime customer, therefore we focus on approving legitimate orders and never charge you
                for a decline.
              </p>
            </Col>
          </Row>
        </article>
        <article className="our-difference">
          <Row>
            <Col md={7}>
              <h4>See more <em>with less</em></h4>
              <h2><span className="green">Our Difference is</span> Our People<span className="green">.</span></h2>
              <p>We're a passionate team of data analysts, developers, and reviewers. We approve more orders than our competitors, resulting in happier shoppers and better results for our customers.</p>
              {/* <a href="/careers">Join our team | </a> */}
            </Col>
            <Col md={5}>
              <h3>Features</h3>
              <ul>
                <li>Customer-Focused <Icon i="PhoneRing" /></li>
                <li>Part of Your Team <Icon i="People" /></li>
                <li>Easy to Integrate <Icon i="TileGrid" /> </li>
              </ul>
            </Col>
          </Row>
        </article>
        <article className="making-sense">
          <Row>
            <Col md={12}>
              <h4>Making technology make sense for your business</h4>
              <h2><span className="green">Revolutionizing Fraud Protection Standards</span> For Modern eCommerce Businesses<span className="green">.</span></h2>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <hr />
              <p>Apruvd started in May of 2015 with one principle in mind: <strong>merchants deserve to grow their business without having to deal with credit card fraud.</strong></p>
            </Col>
            <Col md={6}>
              <hr />
              <p>Central to our history of high client satisfaction and retention, our relationships within the industry are founded on a commitment to accessibility, transparency, and communication.</p>
            </Col>
          </Row>
        </article>
        <article className="mission">
          <Row>
            <Col md={5} className="right">
              <h2 className="green">Mission.</h2>
            </Col>
            <Col md={7}>
              <h3>To be a positive force for merchants in the hostile world of fraud, where businesses have few allies.</h3>
            </Col>
          </Row>
          <Row className="get-started">
            <Col className="center">
              <LinkButton className="medium" to="/contact">Get Started</LinkButton>
            </Col>
          </Row>
        </article>
        <Footer />
      </div>
    )
  }
}

export default About
