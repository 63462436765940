import React from 'react'
import { Route, Switch } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'styles/App.css'
import 'styles/fonts.css'

import About from 'pages/About'
import Careers from 'pages/Careers'
import Contact from 'pages/Contact'
import Customers from 'pages/Customers'
import Home from 'pages/Home'
import Plans from 'pages/Plans'
import Pricing from 'pages/Pricing'
import Services from 'pages/Services'
import Security from 'pages/Security'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import Terms from 'pages/Terms'

const tagManagerArgs = {
    gtmId: 'GTM-KSF6983'
}
TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/about" component={About} />
      <Route path="/services" component={Services} />
      <Route path="/customers" component={Customers} />
      <Route path="/plans" component={Plans} />
      <Route path="/cpsh-2022" component={Pricing} />
      <Route path="/contact" component={Contact} />
      <Route path="/success" component={Contact} />
      <Route path="/careers" component={Careers} />
      <Route path="/security" component={Security} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/terms-of-use" component={Terms} />
      <Route component={Home} />
    </Switch>
  )
}

export default App
