import React, { Component } from 'react'
import { Row, Col, Carousel } from 'react-bootstrap'

import 'styles/Pricing.css'
import LinkButton from 'components/LinkButton'
import PageHeader from 'components/PageHeader'
import ScrollLink from 'components/ScrollLink'
import Footer from 'components/Footer'
import Icon from 'components/Icon'

class Pricing extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="Pricing">
        <PageHeader />
        <article className="welcome">
          <Row className="service-intro">
              <Col md={12} className="center">
                <h4 className="green">Service Plans &amp; Pricing</h4>
                <h2>Fraud Protection As Unique As <span className="green">Your Business.</span></h2>
                <h5>Running a business is complicated - paying to protect it shouldn't be.</h5>
              </Col>
          </Row>
          <Row>
            <Col md={12} className="flex-center welcome-list">
              <ul>
                <li><Icon i="Apruvd" />Simple Plans, No Monthly Minimums</li>
                <li><Icon i="Apruvd" />No Setup Costs Or Integration Fees</li>
                <li><Icon i="Apruvd" />No Long-Term Contract Or Cancellation Fees</li>
                <li><Icon i="Apruvd" />Never Pay A Fraud-Related Chargeback Again</li>
              </ul>
            </Col>
          </Row>
          <Row className="asq">
            <Col md={12} className="center">
              <h3>All Plans Backed by Apruvd<strong><em>Assurance</em></strong></h3>
              <a className="green" href="/plans">Learn More &gt;</a>
              <LinkButton className="smaller" to="/contact">Request a Quote</LinkButton>
            </Col>
          </Row>
          <Row className="lm">
            <Col md={12} className="center more">
              <ScrollLink to="#plans" offset={-10}>Explore Pricing &amp; Plans</ScrollLink>
            </Col>
          </Row>
        </article>
        <article className="plans">
          <Row className="intro">
            <Col md={12} className="center">
              <h2 className="mob-only">Pricing</h2>
              <h3>Find The Plan That's Right For You<span className="green">.</span></h3>
              <h5>Start Protecting Your Revenue Today.</h5>
              <p>
                Apruvd provides guaranteed fraud protection to eCommerce businesses. We review online orders and 
                return a decision, either Approved or Declined, often referred to as "Decision-As-A-Service." 
                Approved orders are guaranteed against fraud risk, and <em>declines are free of charge</em>.
              </p>
              <p>
                <strong>Apruvd can handle all orders, just declines, or a custom mix — We fit into your business and systems, 
                not the other way around.</strong>
              </p>
            </Col>
          </Row>
          <Row id="plans" className="tiles">
            <div className="desk-only">
              <Col className="center" md={4}>
                <div className="tile complete">
                  <h5 className="title">Apruvd<span>Complete</span></h5>
                  <h6>All Orders</h6>
                  <h3><span className="num">0.75% </span><span>Per Approved Transaction</span></h3>
                  <div className="box">
                    <ul>
                      <li><Icon i="Apruvd" />Complete Chargeback Coverage</li>
                      <li><Icon i="Apruvd" />Domestic &amp; International Sales</li>
                      <li><Icon i="Apruvd" />Only Pay For Approved Orders</li>
                      <li><Icon i="Apruvd" />Zero Risk</li>
                    </ul>
                    <p className="desc">
                      Apruvd will review and decision all incoming transactions.*
                      <br />
                      <span className="small">*not otherwise indemnified</span>
                    </p>
                  </div>
                  <LinkButton to="/contact">Get Started</LinkButton>
                </div>
              </Col>
              <Col className="center" md={4}>
                <div className="tile revive">
                  <h5 className="title">Apruvd<span>Revive</span></h5>
                  <h6>Declines</h6>
                  <h3><span className="num">3.5% </span><span>Per Approved Transaction</span></h3>
                  <div className="box">
                    <ul>
                      <li><Icon i="Apruvd" />Chargeback Coverage On All Approvals</li>
                      <li><Icon i="Apruvd" />Domestic &amp; International Sales</li>
                      <li><Icon i="Apruvd" />Only Pay For Approved Orders</li>
                    </ul>
                    <p className="desc">
                      Apruvd will review any transaction flagged in your system as high or medium risk or that would 
                      otherwise be declined.
                    </p>
                  </div>
                  <LinkButton to="/contact">Get Started</LinkButton>
                </div>
              </Col>
              <Col className="center" md={4}>
                <div className="tile custom">
                  <h5 className="title">Apruvd<span>Custom</span></h5>
                  <h6>Custom Mix</h6>
                  <h3><span className="num">2.0% </span><span>Per Approved Transaction</span></h3>
                  <div className="box">
                    <ul>
                      <li><Icon i="Apruvd" />Chargeback Coverage On All Approvals</li>
                      <li><Icon i="Apruvd" />Domestic &amp; International Sales</li>
                      <li><Icon i="Apruvd" />Only Pay For Approved Orders</li>
                    </ul>
                    <p className="desc">
                      Apruvd will review any specific subset of transactions including anything that would previously 
                      be considered high-risk or declined.
                    </p>
                  </div>
                  <LinkButton to="/contact">Get Started</LinkButton>
                </div>
              </Col>
            </div>
            <div className="mob-only">
              <Carousel>
                <Carousel.Item className="tile complete">
                  <h5 className="title">Apruvd<span>Complete</span></h5>
                  <h6>All Orders</h6>
                  <h3><span className="num">0.75% </span><span>Per Approved Transaction</span></h3>
                  <div className="box">
                    <ul>
                      <li><Icon i="Apruvd" />Complete Chargeback Coverage</li>
                      <li><Icon i="Apruvd" />Domestic &amp; International Sales</li>
                      <li><Icon i="Apruvd" />Only Pay For Approved Orders</li>
                      <li><Icon i="Apruvd" />Zero Risk</li>
                    </ul>
                    <p className="desc">
                      Apruvd will review and decision all incoming transactions.*
                      <br />
                      <span className="small">*not otherwise indemnified</span>
                    </p>
                  </div>
                  <LinkButton to="/contact">Get Started</LinkButton>
                </Carousel.Item>
                <Carousel.Item className="tile revive">
                  <h5 className="title">Apruvd<span>Revive</span></h5>
                  <h6>Declines</h6>
                  <h3><span className="num">3.5% </span><span>Per Approved Transaction</span></h3>
                  <div className="box">
                    <ul>
                      <li><Icon i="Apruvd" />Chargeback Coverage On All Approvals</li>
                      <li><Icon i="Apruvd" />Domestic &amp; International Sales</li>
                      <li><Icon i="Apruvd" />Only Pay For Approved Orders</li>
                    </ul>
                    <p className="desc">
                      Apruvd will review any transaction flagged in your system as high or medium risk or that would 
                      otherwise be declined.
                    </p>
                  </div>
                  <LinkButton to="/contact">Get Started</LinkButton>
                </Carousel.Item>
                <Carousel.Item className="tile custom">
                  <h5 className="title">Apruvd<span>Custom</span></h5>
                  <h6>Custom Mix</h6>
                  <h3><span className="num">2.0% </span><span>Per Approved Transaction</span></h3>
                  <div className="box">
                    <ul>
                      <li><Icon i="Apruvd" />Chargeback Coverage On All Approvals</li>
                      <li><Icon i="Apruvd" />Domestic &amp; International Sales</li>
                      <li><Icon i="Apruvd" />Only Pay For Approved Orders</li>
                    </ul>
                    <p className="desc">
                      Apruvd will review any specific subset of transactions including anything that would previously 
                      be considered high-risk or declined.
                    </p>
                  </div>
                  <LinkButton to="/contact">Get Started</LinkButton>
                </Carousel.Item>
              </Carousel>
            </div>
          </Row>
          <Row>
            <Col className="center" md={12}>
              <p className="discl">
                *Your monthly invoice is determined by the above percentage of each order we <em>approve</em>. There are 
                no other fees or charges, and there are no monthly minimums.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={1} xs={6}>
              <h4 className="mob-only">Not all review services are created equal.</h4>
            </Col>
            <Col className="center fp" md={6} xs={6}>
              <p>
                Our pricing is much like our approach to fraud protection - flexible and customizable. We work hard 
                to ensure you receive the most comprehensive strategy that's tailored to your business and budget needs.
              </p>
            </Col>
            <Col className="start" md={5} xs={12}>
              <h4>Not sure where to start? <em>We can help.</em></h4>
              <LinkButton to="/services">Start Here <span className="green">&gt;</span></LinkButton>
            </Col>
          </Row>
        </article>
        <Footer />
      </div>
    )
  }
}

export default Pricing
