import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import PageHeader from 'components/PageHeader'
import Footer from 'components/Footer'

class Security extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="Security">
        <PageHeader />
        <Row className="wot">
          <Col md={12}>
            <h3>Information Security Policy</h3>
            <h6>LAST REVISION DATE: June 15, 2021</h6>
            <p>
              We have an Information Security Program in place that is communicated throughout the organization. The objective of 
              the Information Security Policy is to ensure the information security of Apruvd, LLC and it's customers, and to 
              minimize the risk of damage by preventing security incidents and reducing their potential impact. 
            </p>
            <p>
              To achieve this, we shall establish comprehensive information security management systems covering people, processes, 
              technologies of all business, operational and functional units within Apruvd. We shall implement all reasonable 
              and appropriate security mechanisms for all our information assets at granular levels, increasing the effectiveness 
              of our internal control systems. We are committed to building the necessary infrastructure, knowledge and resource base to 
              meet information security requirements within a focus on continual improvement. Our Information Security Program 
              follows the criteria set forth by the SOC 2 Framework. SOC 2 is a widely known information security auditing procedure 
              created by the American Institute of Certified Public Accountants.
            </p>
            <p>
              Our organization undergoes regular independent third-party assessments to test our security and compliance controls. 
              We perform an independent third-party penetration at least annually to ensure that the security posture of our services is uncompromised.
            </p>
            <p>
              Our team members are also required to go through employee security awareness training covering industry standard practices 
              and information security topics such as phishing and password management. Roles and responsibilities related to our 
              Information Security Program and the protection of our customer’s data are well defined and documented. Our team members are 
              required to review and accept all of the security policies. Additionally, all team members are required to sign and adhere to an 
              industry standard confidentiality agreement.
            </p>
            <h3>Data Encryption</h3>
            <ul>
              <li>All databases are encrypted at rest.</li>
              <li>Our applications encrypt in transit with TLS/SSL only.</li>
            </ul>
            <h3>Business Continuity and Disaster Recovery</h3>
            <p>
              We use our data hosting provider’s backup services to reduce any risk of data loss in the event of a hardware failure. 
              We utilize monitoring services to alert the team in the event of any failures affecting users. We also employ a number 
              of disaster prevention methods including but not limited to:
            </p>
            <ul>
              <li>We actively monitor and log various cloud services.</li>
              <li>We perform vulnerability scanning and actively monitor for threats.</li>
              <li>We have a process for handling information security events which includes escalation procedures, rapid mitigation and communication.</li>
            </ul>
            <h3>Access Security</h3>
            <p>
              We take access control very seriously, and follow the principle of least privilege with respect to identity and access management.
              Access to cloud infrastructure and other sensitive tools are limited to authorized employees who require it for their role. 
              Where available we have Single Sign-on (SSO), 2-factor authentication (2FA) and strong password policies to ensure access 
              to cloud services are protected.
            </p>
            <p>
              All team members are required to adhere to a minimum set of password requirements and complexity for access. We perform quarterly 
              access reviews of all team members with access to sensitive systems. All company issued laptops utilize a password manager for 
              team members to manage passwords and maintain password complexity.
            </p>
            <h3>Vendor and Risk Management</h3>
            <p>
              We undergo at least annual risk assessments to identify any potential threats, including considerations for fraud. Vendor risk is 
              determined and the appropriate vendor reviews are performed prior to authorizing a new vendor.
            </p>
            <br />
            <br />
            <p className="center">
              If you have any questions, comments or concerns or if you wish to report a potential security issue, 
              please contact <a href="mailto:security@apruvd.com">security@apruvd.com</a>.
            </p>
          </Col>
        </Row>
        <Footer />
      </div>
    )
  }
}

export default Security
