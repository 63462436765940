import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

class LinkButton extends Component {
  render() {
    return (
      <Link to={this.props.to}><Button className={this.props.className} color="primary">{this.props.children}</Button></Link>
    )
  }
}

export default LinkButton
