import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import 'styles/Plans.css'
import CTA from 'components/CTA'
import PageHeader from 'components/PageHeader'
import Footer from 'components/Footer'
import Icon from 'components/Icon'
import LinkButton from 'components/LinkButton'
import ScrollLink from 'components/ScrollLink'

import RightArrow from 'images/right-arrow.png'


class Plans extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="Plans">
        <PageHeader />
        <article className="welcome">
          <Row>
            <Col md={8} className="tagline">
              <h4><span className="green">How we work</span></h4>
              <h1>
                <span className="green">Protection As Unique As</span> Your Business<span className="green">.</span>
              </h1>
            </Col>
            <Col md={4} className="slogan">
              <p>Keep your systems secure, your customers happy, and <em>your business moving forward.</em></p>
              <CTA to="/contact" title="Find your plan" text="Request A Quote" />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="center more">
              <ScrollLink to="#service-plans" offset={-12.5}>Our Difference</ScrollLink>
            </Col>
          </Row>
          <Row id="service-plans" className="service-plans">
            <Col md={6}>
              <h4 className="green">Service Plans</h4>
              <h2><span className="green">Complete Solution</span> <em>Or Layer Us On</em><span className="green">.</span></h2>
              <p>We can <strong><em>do it all</em></strong>, or focus on specific areas that need protection. We make it as easy as possible to <strong><em>help your business grow</em></strong>.</p>
            </Col>
          </Row>
        </article>
        <div id="plans-list">&nbsp;</div>
        <article className="three-tier">
          <Row>
            <Col xs={8}>
            </Col>
            <Col className="right" xs={4}>
              <h3>All Backed By <strong>Apruvd</strong><em>Assurance.</em></h3>
              <a href="/services#apruvd-assurance">Learn More<img className="right-arrow" src={RightArrow} alt="Learn More" /></a>
            </Col>
          </Row>
        </article>
        <article className="detect-decline">
          <Row>
            <Col md={12}>
              <Row>
                <Col xs={7}>
                  <h4><span className="green">Detect Revenue</span></h4>
                  <div>
                    <h2>Don't Break &amp; Build.</h2>
                    <h2 className="green">Layer &amp; Incorporate.</h2>
                  </div>
                  <p>Accurate declines are just as important as accurate approvals - with Apruvd, eliminate false-declines and ensure all good customers get approved.</p>
                </Col>
                <Col xs={5} className="center">
                  <h3>Decline<span className="green"><em>Smarter</em></span>&trade;</h3>
                  <hr />
                  <p>Add us as your declines-only review pipeline and <strong>immediately approve approximately 50% of your flagged declines</strong></p>
                  {/* <a href="/customers">See this plan in action </a> */}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="how-we-work">
            <Col md={12} className="center">
              <h4 className="green">Smart and Simple</h4>
              <h2>How We Work<span className="green">.</span></h2>
              <h5>3 easy ways to get started</h5>
              <Row>
                <Col md={4} className="center manual">
                  <h3>Manual</h3>
                  <h4>Data Input</h4>
                  <p>Through our layered approach, it's as simple as creating a login and password —
                    run a small batch of hard-to-decision orders through us, either through direct
                     input or simply upload a spreadsheet of order data via our portal.</p>
                </Col>
                <Col md={4} className="center platform">
                  <h3>Platform</h3>
                  <h4>Integration</h4>
                  <p>Integrate us into your processes and eCommerce flow. A responsive and robust
                    experience offering a seamless integration and a best-in-class technology partnership.</p>
                </Col>
                <Col md={4} className="center api">
                  <h3>API</h3>
                  <h4>Integration</h4>
                  <p>A more systematic approach, a fully-integrated workflow provides a 360 approach to 
                    your review process, making it hands-off and fully functional.</p>
                </Col>
              </Row>
              <p>Fraud is complicated enough, we make it simple to keep your business safe.</p>
              <LinkButton to="/contact">Get Started</LinkButton>
            </Col>
          </Row>
        </article>
        <article className="features">
          <Row>
            <Col md={1}>
            </Col>
            <Col md={4}>
              <h2><span className="green">Features</span>.</h2>
              <h5>Not all order review services are created equal.</h5>
            </Col>
            <Col md={6} className="feature-list">
              <Row>
                <Col md={4}>
                  <ul>
                    <li><Icon i="Apruvd" />Lightweight</li>
                    <li><Icon i="Apruvd" />Zero Risk</li>
                  </ul>
                </Col>
                <Col md={8}>
                  <ul>
                    <li><Icon i="Apruvd" />Affordable</li>
                    <li><Icon i="Apruvd" />Only Pay for Approved Orders</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </article>
        <article className="integrations">
          <Row>
            <Col md={1}>
            </Col>
            <Col md={5} className="flex-end">
              <h2>Integrations<span className="green">.</span></h2>
            </Col>
            <Col md={5} className="right">
              <h5>Technology Integrations</h5>
              <h3 className="green">Seamlessly Integrate With <br />Our Partner Platforms.</h3>
              <p>We work with the leading eCommerce platforms and can have you up and running same-day.</p>
            </Col>
            <Col md={1}>
            </Col>
          </Row>
        </article>
        <article className="get-started">
          <Row>
            <Col className="center">
              <h3>Ready to Get Started?</h3>
              <LinkButton to="/contact">Let's Connect</LinkButton>
            </Col>
          </Row>
        </article>
        <Footer />
      </div>
    )
  }
}

export default Plans
