import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

class ExternalButton extends Component {
  render() {
    return (
      <Button className={this.props.className} href={this.props.to} color="primary">{this.props.children}</Button>
    )
  }
}

export default ExternalButton
