import React, { Component } from 'react'

class Icon extends Component {
  render() {
    const icons = {
      "Apruvd": { src: 'images/apruvd-checkmark.png', title: 'Apruvd Logo' },
      "BarChart": { src: 'images/bar-chart-icon.png', title: 'Bar Chart Icon' },
      "People": { src: 'images/people-icon.png', title: 'People Icon' },
      "PhoneRing": { src: 'images/phone-icon.png', title: 'Phone Ringing Icon' },
      "TileGrid": { src: 'images/tile-grid-icon.png', title: 'Tile Grid Icon' },
      "ShieldLock": { src: 'images/shield-lock-icon.png', title: 'Locked Shield Icon' },
      "GolfCup": { src: 'images/golf-cup-icon.png', title: 'Golf Flag Icon' },
      "Drill": { src: 'images/drill-icon.png', title: 'Drill Icon' },
      "Camera": { src: 'images/camera-icon.png', title: 'Camera Icon' },
      "Car": { src: 'images/car-icon.png', title: 'Automotive Icon' },
      "Makeup": { src: 'images/makeup-icon.png', title: 'Makeup Supplies Icon' },
      "Fashion": { src: 'images/fashion-icon.png', title: 'Purse Icon' },
      "AbsStack": { src: 'images/abs-stack-icon.png', title: 'Abstract Stacks Connected Icon' },
      "AbsDrone": { src: 'images/abs-drone-icon.png', title: 'Abstract Circular Grid Icon' },
      "AbsIL": { src: 'images/abs-il-icon.png', title: 'Abstract Bubbles Icon' },
      "AbsBar": { src: 'images/abs-barchart-icon.png', title: 'Abstract Bar Chart Icon' },
      "PurpCancel": { src: 'images/purp-cancel-icon.png', title: 'Purple Cancel Icon' },
      "PurpBar": { src: 'images/purp-bar-icon.png', title: 'Purple Bar Chart Icon' },
      "PurpArrow": { src: 'images/purp-arrow-icon.png', title: 'Purple Diagonal Arrow Icon' },
      "Squiggle": { src: 'images/apruvd-squiggle.png', title: 'Apruvd Checkmark' },
      "ThickSquiggle": { src: 'images/thick-squiggle-icon.png', title: 'Apruvd Checkmark' },
      "Cog": { src: 'images/thick-cog-icon.png', title: 'Green Cog Icon' },
      "ManifyingGlass": { src: 'images/thick-magnifying-icon.png', title: 'Green Magnifying Glass Icon' },
      "Arrow": { src: 'images/caret.png', title: 'Forward Arrow' }
    }
    return (
      <img src={require(`../${icons[this.props.i].src}`).default} alt={icons[this.props.i].title} />
    )
  }
}

export default Icon