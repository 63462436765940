import React, { Component } from 'react'
import { Card } from 'react-bootstrap'

class GridTile extends Component {
  render() {
    return (
      <Card>
        {/* <Card.Img top width="100%" src="/images/apruvd-icon.png" alt="Tile image caption" /> */}
        <Card.Body>
          <Card.Title>{this.props.title}</Card.Title>
          <Card.Text>
            {this.props.body}
          </Card.Text>
        </Card.Body>
      </Card>
    )
  }
}

export default GridTile
