import React, { Component } from 'react'
import { HashLink } from 'react-router-hash-link'

import DownArrow from 'images/down-arrow.png'

class ScrollLink extends Component {
  render() {
    const scrollOffsetPercentage = (el, offset) => {
      const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
      const yOffsetPercent = offset;
      const yOffsetDiff = yCoordinate * (yOffsetPercent * .01)
      window.scrollTo({ top: yCoordinate + yOffsetDiff, behavior: 'smooth' })
    }
    return (
      <HashLink smooth to={this.props.to} scroll={el => scrollOffsetPercentage(el, this.props.offset)}>
        {this.props.children}
        <br />
        <img className="down-arrow" src={DownArrow} alt={this.props.children} />
      </HashLink>
    )
  }
}

export default ScrollLink
