import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { HashLink } from 'react-router-hash-link'

import FooterForm from 'components/FooterForm'
import UpArrow from 'images/up-arrow.png'
import LILogo from 'images/linked-circle.png'

class Footer extends Component {
  render() {
    return (
      <footer>
        <Row className="footer-upper">
          <Col md={5}>
            <h3>Let's Connect<span className="green">.</span></h3>
            <strong>Follow Us</strong>
            <a href="https://www.linkedin.com/company/apruvd-llc/" target="_blank" rel="noreferrer"><img src={LILogo} alt="LinkedIn" /></a>
          </Col>
          <Col md={4} className="center">
            <Row>
              <Col md={6}>
                <ul>
                  <li><strong>Resources</strong></li>
                  <li><a href="https://status.apruvd.com" target="_blank" rel="noreferrer">System Status</a></li>
                  <li><a href="https://app.apruvd.com/public/documentation/" target="_blank" rel="noreferrer">API Documentation</a></li>
                </ul>
              </Col>
              <Col md={6}>
                <ul>
                  <li><strong>About</strong></li>
                  <li><NavLink to="/about">Who We Are</NavLink></li>
                  <li><NavLink to="/services">What We Do</NavLink></li>
                  <li><NavLink to="/customers">Who We Help</NavLink></li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col md={3} className="right">
            <ul>
              <li><strong>Join Our Newsletter</strong></li>
            </ul>
            <div className="newsletter-signup">
              <FooterForm />
            </div>
          </Col>
        </Row>
        <Row className="footer-lower">
          <Col md={10}>
            <p>&copy; Apruvd, LLC {new Date().getFullYear()} All Rights Reserved</p>
            <NavLink to="/terms-of-use">Terms of Use</NavLink>
            <NavLink to={{pathname: "https://apruvd.com/privacy_policy.pdf"}} target="_blank">Privacy Policy</NavLink>
            <NavLink to="/security">Security Policy</NavLink>
          </Col>
          <Col md={2} className="right">
            <HashLink smooth to="#top"><strong>Back to Top</strong><img className="up-arrow" src={UpArrow} alt="Back To Top" /></HashLink>
          </Col>
        </Row>
      </footer>
    )
  }
}

export default Footer
