import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { HashLink } from 'react-router-hash-link'

import 'styles/Customers.css'
import CTA from 'components/CTA'
import PageHeader from 'components/PageHeader'
import Footer from 'components/Footer'
import Icon from 'components/Icon'
import LinkButton from 'components/LinkButton'
import ScrollLink from 'components/ScrollLink'

import RightArrow from 'images/right-arrow.png'
import QuoteMark from 'images/quote.png'
import GlowDot from 'images/glow-dot.png'

class Customers extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="Customers">
        <PageHeader />
        <article className="welcome">
          <Row>
            <Col md={12} className="tagline">
              <h4><span className="green">Who we help</span></h4>
              <h1>
                <span className="green">Helping Your</span>
                <br />Business Grow<span className="green">.</span>
              </h1>
              <p>Detect More Revenue and Approve More Orders Today.</p>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
            </Col>
            <Col md={4} className="slogan">
              <CTA to="/contact" title="Find your plan" text="Request a Quote" />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="center more">
              <ScrollLink to="#customers" offset={0}>Our Difference</ScrollLink>
            </Col>
          </Row>
          <Row id="customers" className="featured">
            <Col md={5}>
            </Col>
            <Col md={7} className="right">
              <h4 className="green">Featured</h4>
              <h1>Customers<span className="green">.</span></h1>
              <p>We're honored to partner with some of the top businesses in their fields, providing
                superior fraud protection and maximized revenue and results.</p>
              <HashLink smooth to="#testimonials">Learn More <span className="green"><img className="right-arrow" src={RightArrow} alt="Learn More" /></span></HashLink>
            </Col>
          </Row>
        </article>
        <article className="case-studies">
          <Row id="case-studies">
            <Col xs={8}>
            </Col>
            <Col xs={4} className="right">
              <blockquote>
                <img className="quote" src={QuoteMark} alt="Open Quote" />
                Apruvd has helped 
                <strong><em> get control of chargeback exposure </em></strong> 
                where all other attempts have not.
                <img className="quote" src={QuoteMark} alt="Close Quote" />
              </blockquote>
              <h5>Pat Kelly</h5>
              <p id="testimonials">Performance Inc. / Nashbar</p>
            </Col>
          </Row>
          <Row className="testimonials">
            <Col md={12}>
              <h2>Testimonials<span className="green">.</span></h2>
            </Col>
          </Row>
          <Row className="test-rows">
            <Col md={4}>
              <blockquote>"Our internal fraud team had the basic tools to screen orders, but nothing that could compare to the depth of research performed by Apruvd"</blockquote>
              <p>Steve Ber</p>
              <p>VP of Finance at Power Equipment Direct</p>
            </Col>
            <Col md={4}>
              <blockquote>"The Apruvd team is fast and effective at authenticating suspected fraud orders. It's a wonderful peace of mind knowing Apruvd is looking out for us!"</blockquote>
              <p>Renee Chandler</p>
              <p>CFO at Renovation Brands</p>
            </Col>
            <Col md={4}>
              <blockquote>"We have our eCommerce risk review on autopilot with Apruvd. They manage all the risk and make the process seamless!"</blockquote>
              <p>Jason Grant</p>
              <p>General Manager at All-Wall Equipment Co., Inc.</p>
            </Col>
          </Row>
          {/* <Row>
            <Col md={11} className="studies">
              <h2>Case Studies<span className="green">.</span></h2>
              <p>We protect our Customers from fraud, turn their declines into revenue and eliminate unnecessary friction and chargebacks.</p>
              <a href="" className="study">
                <img src={PEDBill} alt="Learn More" />
              </a>
              <a href="" className="study">
                <img src={SportsBill} alt="Learn More" />
              </a>
              <a href="" className="study">
                <img src={AllWallBill} alt="Learn More" />
              </a>
            </Col>
          </Row> */}
        </article>
        {/* <article className="testimonials">
          <Row>
            <Col md={12}>
              <h2>Testimonials<span className="green">.</span></h2>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <blockquote>"Our internal fraud team had the basic tools to screen orders, but nothing that could compare to the depth of research performed by Apruvd"</blockquote>
              <p>Steve Ber</p>
              <p>VP of Finance at Power Equipment Direct</p>
            </Col>
            <Col md={4}>
              <blockquote>"The Apruvd team is fast and effective at authenticating suspected fraud orders. It's a wonderful peace of mind knowing Apruvd is looking out for us!"</blockquote>
              <p>Renee Chandler</p>
              <p>CFO at Renovation Brands</p>
            </Col>
            <Col md={4}>
              <blockquote>"We have our eCommerce risk review on autopilot with Apruvd. They manage all the risk and make the process seamless!"</blockquote>
              <p>Jason Grant</p>
              <p>General Manager at All-Wall Equipment Co., Inc.</p>
            </Col>
          </Row>
        </article> */}
        <article className="sell-sell">
          <Row>
            <Col md={12} className="center">
              <h4 className="green">We have your back</h4>
              <h3>Sell <em>Anything</em> To <em>Anyone</em>, <br /><em>Anywhere</em> In The World.</h3>
              <p>Let's take your fraud prevention to the next level.</p>
              <LinkButton to="/plans">Approve More Orders</LinkButton>
            </Col>
          </Row>
        </article>
        <article className="world">
          <Row>
            <Col md={6} className="center">
              <h5>Fraud protection that fits your business.</h5> 
              <h5>Not the other way around.</h5>
              <img src={GlowDot} alt="Glowing Dot Marker" />
            </Col>
            <Col md={6} className="center">
              <h5>Domestic and International Orders</h5>
              <img src={GlowDot} alt="Glowing Dot Marker" />
            </Col>
          </Row>
        </article>
        <article className="keep-jamming">
          <Row>
            <Col xs={8} md={6}>
              <h2><span className="green">Keep your customers happy</span> doing the things that make them happy.</h2>
              <p>
                All orders or some, international or domestic, working with your internal fraud team, 
                or being your fraud team - together we'll make sure you have the most accurate decisioning 
                and the best results.
              </p>
              <LinkButton className="smaller" to="/contact">Get Started Today</LinkButton>
            </Col>
          </Row>
        </article>
        <article className="get-started">
          <Row>
            <Col xs={5} md={6}>
            </Col>
            <Col xs={7} md={6}>
              <Row>
                <Col md={6}>
                  <ul>
                    <li><Icon i="GolfCup" />Sporting Goods</li>
                    <li><Icon i="Drill" />Home Goods &amp; Renovation</li>
                    <li><Icon i="Camera" />Consumer Electronics</li>
                  </ul>
                </Col>
                <Col md={6}>
                <ul>
                    <li><Icon i="Car" />Automotive</li>
                    <li><Icon i="Makeup" />Health &amp; Beauty</li>
                    <li><Icon i="Fashion" />Fashion &amp; Luxury Goods</li>
                  </ul>
                </Col>
              </Row>  
            </Col>
          </Row>
        </article>
        <Footer />
      </div>
    )
  }
}

export default Customers
