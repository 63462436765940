import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Nav, Navbar, Dropdown, NavItem } from 'react-bootstrap'
import ExternalButton from 'components/ExternalButton'
import LinkButton from 'components/LinkButton'
import logo from 'images/apruvd-logo.png'
import menuOpenButton from 'images/mob-menu.png'
import menuCloseButton from 'images/mob-menu-close.png'

class PageHeader extends Component {
  render() {
    return (
      <header className="header">
        <Navbar color="dark" fixed="top">
          <Navbar.Brand href="/"><img className="logo" src={logo} alt="Apruvd - See the good" /></Navbar.Brand>
          <Nav className="menu">
            <NavItem>
              <NavLink activeClassName="" to="/">Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/about">About</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/services">Services</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/customers">Customers</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/plans">Plans</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/contact">Contact</NavLink>
            </NavItem>
          </Nav>
          <div className="nav-buttons">
            <ExternalButton to="https://app.apruvd.com">Login</ExternalButton>
            <LinkButton to="/contact">Let's Talk!</LinkButton>
          </div>
          <Dropdown alignRight className="mob-menu">
            <Dropdown.Toggle>
              <img src={menuOpenButton} alt="Menu" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Toggle>
                <img className="dropdown-toggle" src={menuCloseButton} alt="Close Menu" />
              </Dropdown.Toggle>
              <div className="dropdown-items">
                <Dropdown.Item href="/">Home</Dropdown.Item>
                <Dropdown.Item href="/about">About</Dropdown.Item>
                <Dropdown.Item href="/services">Services</Dropdown.Item>
                <Dropdown.Item href="/customers">Customers</Dropdown.Item>
                <Dropdown.Item href="/plans">Plans</Dropdown.Item>
                <Dropdown.Item href="/contact">Contact</Dropdown.Item>
                <Dropdown.Item className="login" href="https://app.apruvd.com">Login</Dropdown.Item>
                <Dropdown.Item className="request" href="/contact">Request A Quote</Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
      </header>
    )
  }
}

export default PageHeader
