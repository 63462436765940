import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import PageHeader from 'components/PageHeader'
import Footer from 'components/Footer'

class Terms extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="Terms">
        <PageHeader />
        <Row className="wot">
          <Col md={12}>
            <h3>APRUVD, LLC</h3>
            <h3>Terms Of Use and Service</h3>
            <h6>LAST UPDATED: OCTOBER 12, 2022</h6>
            <p>
              The following is an agreement ("Agreement") between you (hereinafter referred to as "you," "your," or "client")
              and Apruvd, LLC, an Arizona limited liability company and/or its affiliates (collectively, and individually,
              "Apruvd", "we", "our", or "us"). Please read these Terms of Use carefully before using our Site or services. 
              By selecting "I Agree," or by using services provided by Apruvd now existing or later developed, including services 
              on the Apruvd website (the "Site") (collectively, the "Services"), or byaccessing, browsing, subscribing to, or 
              utilizing any content or functionality of apruvd.com, whether as a guest or a client, you acknowledge that you have 
              read, have understood, and agree to be bound by this Agreement, together with any documents expressly incorporated 
              by reference, and to comply with all applicable laws and regulations and further waive any rights or requirements 
              under any law or regulation in any jurisdiction which requires an original (non-electronic) signature or delivery or 
              retention of non-electronic records, to the extent permitted under applicable mandatory law. The material provided 
              through our Site and the Services is protected by law, including, but not limited to, United States copyright law 
              and international treaties. The Site and the Services are controlled and operated by Apruvd from its offices within 
              the United States. Apruvd makes no representation that materials on the Site and in the Services are appropriate or 
              available for use in other locations, and access to them from territories where their contents are illegal is 
              prohibited. Those who choose to access the Site and the Services from other locations do so on their own initiative 
              and are responsible for compliance with all applicable local laws
            </p>
            <p>
              If you do not agree to these terms, click "Cancel" and do not access the Site or use any of the Services.
            </p>
            <p>
              From time to time, Apruvd may modify this Agreement and post those modifications to the applicable Site. All
              changes are effective immediately when we post them, and apply to all access to and use of the Site and the
              Services thereafter. Your use of the Site or any Services after any such modification constitutes your acceptance
              of the modified Agreement. You are expected to check this page from time to time so you are aware of any changes, 
              as they are binding on you.
            </p>
            <p>
              By signing the merchant agreement presented to you and providing your payment information, you agree to the
              terms and conditions set forth in the merchant agreement which may include a recurring monthly charge.
            </p>
            <h4>1. YOUR ACCOUNT</h4>
            <p>
              We reserve the right to withdraw or amend the Site or Services, and any service or material we provide on the
              Site or through the Services, in our sole discretion without notice. We will not be liable if for any reason all or
              any part of the Site or Services is unavailable at any time or for any period. From time to time, we may restrict
              access to some parts of the Site or Services, or the entire Site or Services, to users, including registered users.
            </p>
            <p>
              You are responsible for making all arrangements necessary for you to have access to the Site and ensuring that
              all persons who access the Site or Services through your internet connection are aware of and shall comply with
              these Terms of Use and Service.
            </p>
            <p>
              By using the Site and Services, you agree that you (i) are able to create a binding legal obligation, (ii) will use
              the Site and Services at all times in compliance with these Terms of Use and Service, (iii) will safeguard your
              password, and supervise the use of your account, and (iv) have the right to provide any information that you
              submit to the Site or through the Services, that such information is true, accurate, and correct, and that you will
              update any information that becomes outdated or inaccurate.
            </p>
            <p>
              To access the Site or some of the resources it offers, you may be asked to provide certain registration details or 
              other information. It is a condition of your use of the Site that all the information you provide on the Site is 
              correct, current, and complete. We may terminate your access to our Site or to the Services if we learn that you have 
              provided us with false or misleading registration data. You agree that all information you provide to register with 
              this Site or our Services, including but not limited to through the use of any interactive features on the Site or 
              Services, is governed by our Privacy Notice, and you consent to all actions we take with respect to your information 
              consistent with our Privacy Notice.
            </p>
            <p>
              If you choose, or are provided with, a user name, password, or any other piece of information as part of our security 
              procedures, you must treat such information as confidential, and you must not disclose it to any other person or 
              entity. You also acknowledge that your account is personal to you and agree not to provide any other person with 
              access to this Site or portions of it using your user name, password, or other security information. You are solely 
              responsible for maintaining the confidentiality of your password and account, and you are solely responsible for 
              all use of your password or account, whether authorized by you or not. Apruvd cannot and will not be liable for 
              any loss or damage arising from your failure to comply with the terms of this Agreement. You agree to notify us 
              immediately of any unauthorized access to or use of your user name or password or any other breach of security. You 
              also agree to ensure that you exit from your account at the end of each session. You should use particular caution 
              when accessing your account from a public or shared computer so that others are not able to view or record your 
              password or other personal information.
            </p>
            <p>
              You are responsible for maintaining the security of your account and the account settings that reflect your
              preferences. You are solely responsible for any activity that occurs under your account. Apruvd cannot and will
              not be liable for any loss or damage arising from your failure to comply with the terms of this Agreement. You
              agree to notify us immediately of any unauthorized access to or use of your user name or password or any other
              breach of security. You also agree to ensure that you exit from your account at the end of each session. You
              should use particular caution when accessing your account from a public or shared computer so that others are
              not able to view or record your password or other personal information.
            </p>
            <p>
              If you have opted in to receive special offers from Apruvd via email, or otherwise, you can unsubscribe via the 
              links provided in the email at any time or by contacting us at info@apruvd.com. By opting in to receive special 
              offers from us, you consent to receiving, from time to time, text messages or emails which may include alerts, 
              promotions, offers, polls, and giveaways.
            </p>
            <p>
              We have the right to disable any user name, password, or other identifier, whether chosen by you or provided by
              us, at any time if, in our sole discretion for any or no reason, including if, in our opinion, you have violated 
              any provision of these Terms of Use and Service.
            </p>
            <h4>2. PROOF OF CONCEPT</h4>
            <p>
              From time-to-time, Apruvd may offer a "Proof of Concept" (as defined below) of its Services to you free of
              charge on a limited basis, at its sole discretion. Additional terms and conditions may be applicable, and may
              appear on Apruvd's Site, Services, or in a separate agreement. Any such additional terms and conditions are
              incorporated into this Agreement by reference and are legally binding. Any data you share with Apruvd during
              the creation of the proof of concept, and any customizations Apruvd makes to the data for you during the course
              of Apruvd's creation of the Proof of Concept, may be permanently lost unless you later purchase Services
              through Apruvd. As used herein, a "Proof of Concept" is a demonstration, the purpose of which is to verify that
              certain concepts or theories have the potential for real-world application. Proof of Concept is therefore a
              prototype that is designed to determine feasibility, but does not represent deliverables.
            </p>
            <h4>3. OWNERSHIP AND PROPRIETARY INFORMATION</h4>
            <p>
              The content and information on the Site and Services ("Apruvd Content") is proprietary to Apruvd or its
              licensors, suppliers, publishers, rights holders, or other content providers (collectively, and individually,
              "Apruvd Content Providers") and are protected by United States and international copyright, trademark, patent,
              trade secret, and other intellectual property or proprietary rights laws. You agree not to modify, copy, distribute,
              transmit, share, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or 
              resell any Apruvd Content obtained from or through the Site or Services.
            </p>
            <h4>4. USE RESTRICTIONS AND INTELLECTUAL PROPERTY</h4>
            <p>
              This Site or Services may contain proprietary and copyright information, the terms of which must be observed
              and followed. Any unauthorized use of any content contained on this Site or the Services may violate copyright
              laws, trademark laws, the laws of privacy and publicity, and communications regulations and statutes. You may
              not modify, publish, transmit, participate in the transfer or sale of, create derivative works from, or in any way
              exploit any of the content contained on this Site in whole or in part. Any questions concerning whether a
              trademark is a U.S trademark owned by Apruvd should be referred to Apruvd at 
              <a href="mailto:info@apruvd.com"> info@apruvd.com</a>.
            </p>
            <p>
              The Site and its entire contents, features, and functionality (including but not limited to all information, 
              software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof ("Apruvd 
              Content") is owned by Apruvd, its licensors, or other providers of such material (collectively, and individually, 
              "Apruvd Content Providers") and are protected by United States and international copyright, trademark, patent, 
              trade secret, and other intellectual property or proprietary rights laws. You agree not to modify, copy, 
              distribute, transmit, share, display, perform, reproduce, publish, license, create derivative works from, transfer, 
              or sell or re-sell any Apruvd Content obtained from or through Apruvd.
            </p>
            <p>
              You also may not, without the permission of Apruvd, "mirror" any material contained on this Site or Services on
              any other server. Any unauthorized use of any content contained on this Site or Services may violate copyright
              laws, trademark laws, the laws of privacy and publicity, and communications regulations and statutes.
            </p>
            <p>
              You will not upload, post, or otherwise make available on this Site or via the Services, any material protected by
              copyright, trademark or other proprietary right without the express permission of the owner of the copyright,
              trademark, or other proprietary right. You will indemnify Apruvd and its officers, directors, employees, and
              agents for any claims by third parties of infringement of copyrights, trademarks, proprietary rights, or any other
              harm resulting from such a submission of material by you.
            </p>
            <p>
              The Apruvd Content, Site, and Services may not be reproduced, duplicated, copied, sold, resold, or otherwise
              exploited for any commercial purpose without express written consent of Apruvd. You may not frame or utilize
              framing techniques to enclose any trademark, logo, or other proprietary information (including images, text,
              page layout, or form) of Apruvd without express written consent. You may not use any meta tags or any other
              "hidden text" utilizing Apruvd's name or trademarks without the express written consent of Apruvd. You may
              not misuse the Site or Services. You may use the Site or Services only as permitted by law. You must not access 
              or use for any commercial purposes any part of the Site or any services or materials available through the Site.
            </p>
            <p>
              Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing
              those materials. You may store files that are automatically cached by your Web browser for display
              enhancement purposes. If we provide desktop, mobile, or other applications for download, you may download a
              single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you
              agree to be bound by our end user license agreement for such applications.
            </p>
            <p>
              Apruvd and all related names, logos, product and service names, designs, and slogans are trademarks of Apruvd
              or its affiliates or licensors. You must not use such marks without the prior written permission of Apruvd. All
              other names, logos, product and service names, designs, and slogans on this Site or on the Services are the
              trademarks of their respective owners.
            </p>
            <p>
              No right, title, or interest in or to the Site, the Services, or any content on the Site or Services is transferred to
              you, and all rights not expressly granted are reserved by Apruvd. Any use of the Site or Services not expressly
              permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and
              other laws, and any use licenses granted herein terminate if you do not comply with this Agreement.
              To the extent your conduct, as judged by Apruvd in its sole and absolute discretion, restricts or inhibits any other
              users from using or enjoying any part of the Site, we may limit or suspend your access to the Site and seek other
              remedies.
            </p>
            <h4>5. AVAILABILITY</h4>
            <p>
              Information provided on the Site and via the Services may be changed or updated without notice. Apruvd may
              also make improvements and/or changes in the Site or Services described in this information at any time without
              notice. Any information that Apruvd publishes on the Site or Services may contain references or cross references
              to products, programs and services that are not announced or available in your country. Such references do not
              imply that Apruvd intends to announce such products, programs or services in your country. Consult Apruvd for
              information regarding the products, programs and services which may be available to you.
            </p>
            <h4>6. INFORMATION "AS IS" AND DISCLAIMER OF WARRANTIES</h4>
            <p>
              INFORMATION ON THE SITE OR SERVICES MAY CONTAIN TECHNICAL INACCURACIES OR 
              TYPOGRAPHICAL ERRORS, AND IS MADE AVAILABLE SOLELY FOR GENERAL INFORMATION 
              PURPOSES. WE DO NOT WARRANT THE ACCURACY, COMPLETENESS, OR USEFULNESS OF 
              THIS INFORMATION. ANY RELIANCE YOU PLACE ON SUCH INFORMATION IS STRICTLY 
              AT YOUR OWN RISK. WE DISCLAIM ALL LIABILITY AND RESPONSIBILITY ARISING 
              FROM ANY RELIANCE PLACED ON SUCH MATERIALS BY YOU OR ANY OTHER VISITOR 
              TO THE SITE, OR BY ANYONE WHO MAY BE INFORMED OF ANY OF ITS CONTENTS. ALL 
              INFORMATION IS PROVIDED BY APRUVD ON AN "AS IS" BASIS ONLY. APRUVD PROVIDES 
              NO REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, INCLUDING THE 
              IMPLIED WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY, 
              AND NON-INFRINGEMENT. USE OF THE SITE OR SERVICES IS SOLELY AT YOUR RISK. 
              WE DISCLAIM ALL LIABILITY AND RESPONSIBILITY ARISING FROM ANY RELIANCE 
              PLACED ON SUCH MATERIALS BY YOU OR ANY OTHER VISITOR TO THE SITE OR TO 
              THE SERVICES, OR BY ANYONE WHO MAY BE INFORMED OF ANY OF ITS CONTENTS. 
              APRUVD MAKES NO WARRANTY THAT (i) THE SITE OR SERVICES WILL MEET YOUR 
              REQUIREMENTS; (ii) THE SITE OR SERVICES WILL BE UNINTERRUPTED, TIMELY, 
              SECURE, OR ERROR FREE; (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE 
              USE OF THE SITE OR THE USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE; 
              AND (iv) THE QUALITY OF ANY PRODUCTS, INFORMATION, OR OTHER SERVICES 
              PURCHASED OR OBTAINED BY YOU THROUGH THE SITE OR SERVICES WILL MEET YOUR 
              EXPECTATIONS.
            </p>
            <p>
              YOU ARE SOLELY RESPONSIBLE FOR ENSURING THAT YOUR USE OF THE SITE, RELATED 
              SERVICES, OR CONTENT IS IN ACCORDANCE WITH APPLICABLE LAW. APRUVD IS NOT 
              RESPONSIBLE FOR ANY INFORMATION NOT PROVIDED ON THE SITE OR SERVICES. APRUVD 
              DOES NOT GUARANTEE, IMPLICITLY OR EXPLICITLY, ANY RESULTS. ANY MATERIAL 
              DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE OR SERVCICES IS 
              DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR 
              ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE 
              DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR 
              WRITTEN, OBTAINED BY YOU FROM APRUVD OR THROUGH OR FROM THE SITE OR SERVICES 
              SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS OF SERVICE AND USE.
            </p>
            <h4>7. LIMITATION OF LIABILITY</h4>
            <p>
              UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL A
              PRUVD BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR 
              EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, 
              REVENUES, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF APRUVD HAS 
              BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR 
              THE INABILITY TO USE THE SITE OR SERVICES; (ii) THE COST OF PROCUREMENT OF 
              SUBSTITUTE GOODS AND SERVICE RESULTING FROM ANY GOODS, DATA, INFORMATION, OR 
              SERVICE PURCHASED OR OBTAINED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM 
              THE SITE OR SERVICES; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR 
              TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SITE 
              OR SERVICES; OR (v) ANY OTHER MATTER RELATING TO THE SITE OR SERVICES. YOU ALSO 
              AGREE THAT APRUVD WILL NOT BE LIABLE FOR ANY INTERRUPTION OF BUSINESS, ACCESS 
              DELAYS, OR ACCESS INTERRUPTIONS TO THE SITE OR SERVICES, DATA NON-DELIVERY, 
              MIS-DELIVERY, CORRUPTION, DESTRUCTION OR OTHER MODIFICATION, AND EVENTS BEYOND 
              APRUVD'S REASONABLE CONTROL. THE LIMITATIONS OF THIS SUBSECTION SHALL APPLY TO 
              ANY THEORY OF LIABILITY. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF APRUVD 
              EXCEED THE AMOUNT YOU ACTUALLY PAID TO APRUVD, IF ANY, IN THE SIX MONTHS PRIOR 
              TO THE EVENT WHICH GAVE RISE TO THE CLAIM. BECAUSE SOME STATES DO NOT ALLOW THE 
              EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, 
              IN SUCH STATES, OUR LIABILITY IS LIMITED TO THE EXTENT PERMITTED BY LAW.
            </p>
            <p>
              WHEN YOU USE THE APRUVD CONTENT, THE SITE OR THE SERVICES, YOU MAY BE USING THE
              SERVICES OF ONE OR MORE THIRD PARTIES OR OBTAINING PRODUCTS OR SERVICES FROM
              THIRD PARTIES. YOUR USE OF THESE THIRD-PARTY PRODUCTS OR SERVICES MAY BE SUBJECT
              TO THE SEPARATE POLICIES, TERMS OF USE, AND FEES OF THESE THIRD PARTIES. IN NO
              EVENT SHALL APRUVD BE SUBJECT TO DAMAGES RELATED TO ANY SUCH THIRD PARTIES.
            </p>
            <h4>8. PROHIBITED USES</h4>
            <p>
              You may use the Site and Services only for lawful purposes and in accordance with these Terms of Use. You agree 
              not to use the Site or Services:
            </p>
            <ul>
              <li>
                In any way that violates any applicable federal, state, local, or international law or regulation (including,
                without limitation, any laws regarding the export of data or software to and from the US or other countries).
              </li>
              <li>
                For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them 
                to inappropriate content, asking for personally identifiable information, or otherwise.
              </li>
              <li>
                To send, knowingly receive, upload, download, use, or re-use any material that does not comply with the 
                Content Standards set out in these Terms of Use.
              </li>
              <li>
                To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", 
                "chain letter", "spam", or any other similar solicitation.
              </li>
              <li>
                To impersonate or attempt to impersonate Apruvd, an Apruvd employee, another user, or any other
                person or entity (including, without limitation, by using email addresses associated with any of the
                foregoing).
              </li>
              <li>
                To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Site or
                Services, or which, as determined by us, may harm Apruvd or users of the Site or Services or expose
                them to liability.
              </li>
            </ul>
            <p>
              Additionally, you agree not to:
            </p>
            <ul>
              <li>
                Use the Site or Services in any manner that could disable, overburden, damage, or impair the site or
                interfere with any other party's use of the Site, including their ability to engage in real time activities
                through the Site or Services.
              </li>
              <li>
                Use any robot, spider, or other automatic device, process, or means to access the Site or Services for any
                purpose, including monitoring or copying any of the material on the Site or Services.
              </li>
              <li>
                Use any manual process to monitor or copy any of the material on the Site or Services or for any other
                unauthorized purpose without our prior written consent.
              </li>
              <li>
                Use any device, software, or routine that interferes with the proper working of the Site or Services.
              </li>
              <li>
                Introduce any viruses, trojan horses, worms, logic bombs, or other material that is malicious or
                technologically harmful.
              </li>
              <li>
                Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Site or
                Services, the server on which the Site or Services are stored, or any server, computer, or database
                connected to the Site or Services.
              </li>
              <li>
                Attack the Site or the Services via a denial-of-service attack or a distributed denial-of-service attack.
              </li>
              <li>
                Otherwise attempt to interfere with the proper working of the Site or Services.
              </li>
            </ul>
            <h4>9. INDEMNIFICATION</h4>
            <p>
              You agree to defend, indemnify, and hold harmless Apruvd, its affiliates, licensors, and service providers, and its
              and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and
              assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees
              (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use and
              Service, or your use of the Site or Services, including, but not limited to, any use of the Site's or the Service's
              content, services, and products other than as expressly authorized in these Terms of Use and Service or your use
              of any information obtained from the Site or Services.
            </p>
            <h4>10. GOVERNING LAW AND DISPUTE RESOLUTION</h4>
            <p>
              This Agreement and performance hereunder shall exclusively be governed by, and construed in accordance with,
              the laws of the state of Arizona (without giving effect to its conflict of laws principles). The parties agree to
              submit any claim, dispute, or disagreement to mediation before a mutually-agreeable mediator prior any other
              form of dispute resolution. All mediation or any other form of dispute resolution shall take place in Phoenix,
              Arizona and the parties irrevocably waive any objection to such venue.
            </p>
            <p>
              ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE SITE MUST BE 
              COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS 
              PERMANENTLY BARRED.
            </p>
            <h4>
              11. COLORADO PRIVACY ACT ("CPA"), NEVADA PRIVACY OF INFORMATION COLLECTED ON THE INTERNET FROM CONSUMERS 
              ACT ("NPICICA"), AND VIRGINIA CONSUMER DATA PROTECTION ACT ("VCDPA") DISCLOSURES
            </h4>
            <h4>CATEGORIES OF PERSONAL INFORMATION COLLECTED</h4>
            <p>
              We collect the following categories of information: identifiers (such as your name and email address), personal 
              information (such as your name and contact information), internet or other similar network activity, geolocation 
              data, and inferences drawn from the collected Personal Information. This information is collected directly from 
              you when you provide it to us (for example when you submit a form requesting more information) or automatically 
              as you navigate through the Services. We use this information for one or more legitimate business purposes, 
              including to improve our Services and offer information about our Services to you.
            </p>
            <h4>RIGHTS OF COLORADO, NEVADA, AND VIRGINIA RESIDENTS</h4>
            <p>
              If you are a resident of Colorado, Nevada, or Virginia, you have other rights under your respective states' 
              consumer privacy statutes:
            </p>
            <ul>
              <li>
                <strong>Right of Access:</strong> You can access your collected personal information by contacting us 
                at <a href="mailto:support@apruvd.com">support@apruvd.com</a>.
              </li>
              <li>
                <strong>Right to correct, update, or delete:</strong> You can correct, update or request deletion of your 
                personal information by contacting us at <a href="mailto:support@apruvd.com">support@apruvd.com</a>. We can't 
                make changes to or delete your information in some situations where it is necessary for us to maintain your 
                information, for example if we need the information to comply with applicable law.
              </li>
              <li>
                <strong>Right to Request Disclosure of Information Collected:</strong> Please contact us at <a href="mailto:support@apruvd.com">
                support@apruvd.com</a> to request further information about the categories of personal information we have 
                collected about you, where we collected your personal information, and for what purpose we use your personal 
                information.
              </li>
              <li>
                <strong>Right to Disclosure of Information Sold and Right to Opt-Out:</strong> You have the right to know what 
                information of yours we have sold, and you have the right to opt-out of any sale of your information. We do not 
                sell any of your information. If you have any questions about these rights, please contact us  
                at <a href="mailto:support@apruvd.com">support@apruvd.com</a>.
              </li>
              <li>
                <strong>Right to Disclosure of Targeted Advertising and Right to Opt-Out (Colorado &amp; Virginia):</strong> 
                If you are a resident of Colorado or Virginia, you have the right to know what information of yours we have 
                processed for targeted advertising. If you have any questions about these rights or wish to opt-out of any 
                processing of your personal information as it relates to targeted advertising, please contact us  
                at <a href="mailto:support@apruvd.com">support@apruvd.com</a>.
              </li>
              <li>
                <strong>Right to Disclosure of Profiling (Colorado &amp; Virginia):</strong> If you are a resident of Colorado 
                or Virginia, you have the right to know what information of yours we have processed for the purposes of profiling 
                in furtherance of decisions that produce legal or similarly significant effects concerning the consumer. If you 
                have any questions about these rights or wish to opt-out of any processing of your personal information as it 
                relates to profiling, please contact us at <a href="mailto:support@apruvd.com">support@apruvd.com</a>.
              </li>
              <li>
                <strong>Colorado Right to Non-Discrimination:</strong> We do not and will not discriminate against you if you 
                exercise your rights under the CPA.
              </li>
              <li>
                <strong>Nevada Right to Non-Discrimination:</strong> We do not and will not discriminate against you if you 
                exercise your rights under the NPICICA.
              </li>
              <li>
                <strong>Virginia Right to Non-Discrimination:</strong> We do not and will not discriminate against you if you 
                exercise your rights under the VCDPA.
              </li>
            </ul>
            <p>
              When you contact us regarding any of your rights under the CPA, NPICICA, or VCDPA we will verify your identity 
              before we provide any information. If you have any questions or comments about your rights under the CPA, NPICICA, 
              or VCDPA please contact us at <a href="mailto:support@apruvd.com">support@apruvd.com</a>.
            </p>
            <h4>12. TRANSMISSIONS</h4>
            <p>
              Any material, information, or idea you transmit to or post on the Site or via the Services by any means will be
              treated as non-confidential and non-proprietary, and may be disseminated or used by Apruvd or its affiliates for
              any purpose whatsoever, including, but not limited to, developing, manufacturing and marketing its products.
              You are prohibited from posting or transmitting to or from the Site or Services any unlawful, threatening,
              libelous, defamatory, obscene, scandalous, inflammatory, pornographic, or profane material, or any other
              material that could give rise to any civil or criminal liability under the law.
            </p>
            <p>
              "Customer Data" means all data, information, or content provided, uploaded, recorded, or posted by any Authorized 
              User during and in connection with its authorized use of the Services published or displayed on or through the 
              Services or transmitted to or shared with other users. Customer Data may include protected health information (PHI) 
              but does not include Aggregated Data. Any Customer Data, material, information, or idea you transmit to the Site 
              or through the Services by any means will be treated in accordance with Apruvd's Privacy Notice.
            </p>
            <p>
              You grant us the limited license to process the Customer Data you transmit through our Services. You represent and 
              warrant that you own or control all rights in, to, and under the Customer Data you transmit through our Services, 
              and have received the applicable consent to collect, use, and process such Customer Data, and have the right to 
              grant the license granted above to us and our affiliates and service providers, and each of their and our 
              licensees, successors, and assigns.
            </p>
            <p>
              You understand and acknowledge that you are responsible for any Customer Data you submit or transmit, and you, 
              not Apruvd, have full responsibility for such content, including its legality, reliability, accuracy, and 
              appropriateness.
            </p>
            <p>
              The Services may access and transfer information over the internet and link to third party sites and portals. 
              You understand and acknowledge that Apruvd does not operate or control the internet or third party sites and 
              portals and (i) viruses, worms, trojan horses, or other undesirable data or software; and/or (ii) unauthorized 
              users (e.g. hackers) may attempt to obtain access to and damage your data, websites, computers, or networks. 
              Apruvd cannot control the flow of data to or from your network and other portions of the internet or the 
              availability of or access to third party sites.
            </p>
            <p>
              To the extent that you provide or Apruvd requires access to personally identifiable information about an 
              individual (hereinafter referred to as "PII"), Apruvd shall after receipt thereof, treat such PII as confidential 
              and safeguard such information from unauthorized use and disclosure. Apruvd will only allow access to those who 
              need the PII to perform Services and agrees that PII will be used solely for the purpose of performing those 
              aforementioned Services.
            </p>
            <h4>13. END USER COMMUNICATIONS</h4>
            <p>
              You agree that Apruvd may contact you by telephone or text messages (including by an automatic telephone
              dialing system) at any of the phone numbers provided by you or on your behalf in connection with an Apruvd
              account, including for marketing purposes. You understand that you are not required to provide this consent as a
              condition of purchasing any property, goods or services. You also understand that you may opt out of receiving 
              text messages from Apruvd at any time, either by texting the word "STOP" using the mobile device that is 
              receiving the messages, or by contacting <a href="mailto:info@apruvd.com">info@apruvd.com</a>. If you do not 
              choose to opt out, Apruvd may contact you as outlined in its User Privacy Statement.
            </p>
            <p>
              Apruvd, at any time now or in the future, may allow you and other end users to use the Site to express opinions 
              and communicate through comments on products, or other communication facilities that may be offered on or through 
              the Site from time to time (collectively "Communities"). In the event of the existence of such Communities, 
              Apruvd shall have the right, but not the obligation, to monitor the content within the Communities at any time, 
              for any reason, including to determine compliance with this Agreement and any operating rules established by Apruvd, 
              as well as to satisfy any applicable law, regulation or authorized government request. Without limiting the 
              foregoing, Apruvd shall also have the right, but not the obligation, to remove any material from the Communities 
              that Apruvd, in its sole discretion, finds to be in violation of this Agreement or otherwise objectionable. Any 
              opinions, advice, ratings, discussions, comments, or other messages or postings of any kind made by you or any 
              other end user to the Communities are those of the respective author(s) or distributor(s) and not of Apruvd.
            </p>
            <p>
              By way of example, you shall not take any action or upload, download, post, or submit any communication through 
              Apruvd, which: (i) infringes on the intellectual property rights of any third party; (ii) you know is false, 
              misleading, untruthful, or inaccurate; (iii) is unlawful, threatening, abusive, harassment, defamatory, obscene, 
              vulgar, offensive, profane, or otherwise inappropriate as determined by Apruvd in its sole discretion; (iv) 
              constitutes unauthorized advertising or spamming, or otherwise involves commercial activities without the consent 
              of Apruvd; (v) contains software viruses, spamming, manual or automated devices to "crawl" the Site, or any other 
              computer files that disrupt the Site; (vi) impersonates any person or entity; (vii) violates Apruvd's Privacy 
              Notice or otherwise takes any action in violation of Apruvd's guidelines and policies.
            </p>
            <p>
              By posting any materials or other information on or through the Communities, you grant Apruvd a royalty-free, 
              perpetual, irrevocable, non-exclusive license to use, reproduce, modify, publish, edit, translate, distribute, 
              perform, and display such materials or other information alone or as part of other works in any form, media, or 
              technology whether now known or hereafter developed without territorial or time limitations, and to sublicense 
              such rights.
            </p>
            <p>
              The Site or Services may contain links maintained by other third parties. These links are provided solely as a
              convenience to you and not because we endorse or have an opinion about the contents. We expressly disclaim
              any representations regarding the content or accuracy of materials or the privacy practices related to such third
              parties.
            </p>
            <h4>14. TERMINATION</h4>
            <p>
              In its sole and absolute discretion, with or without notice to you, Apruvd may suspend or terminate your use of
              and access to the Site or Services, terminate your account and/or remove and discard anything transmitted by
              you, or information stored, sent, or received via the Site or the Service without prior notice and for any reason,
              including, but not limited to: (i) concurrent access of the Site or Service with identical user identification; (ii)
              permitting another person or entity to use your user identification to access the Site or Service; (iii) any
              unauthorized access or use of the Site or Services; (iv) any violation of these Terms of Use and Service; (v)
              tampering with or alteration of any of the software and/or data files contained in, or accessed through, the Site or
              Services; or (vi) failure to use the Site or Services on a regular basis. You may terminate your account for any
              reason by emailing Apruvd at <a href="mailto:support@apruvd.com">support@apruvd.com</a>. Apruvd shall not be liable 
              to you or any third party for any claims or damages arising out of any termination or suspension of the Site or the 
              Service. Termination, suspension, or cancellation of the Site or the Service or your access rights shall not affect 
              any right or relief to which Apruvd may be entitled, at law or in equity, and all rights granted to you will 
              automatically terminate and immediately revert to Apruvd.
            </p>
            <h4>15. WAIVER AND SEVERABILITY</h4>
            <p>
              No waiver by Apruvd of any term or condition set out in these Terms of Use and Service shall be deemed a
              further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure
              of Apruvd to assert a right or provision under these Terms of Use and Service shall not constitute a waiver of
              such right or provision.
            </p>
            <p>
              If any provision of these Terms of Use and Service is held by a court or other tribunal of competent jurisdiction
              to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the
              minimum extent such that the remaining provisions of the Terms of Use and Service will continue in full force
              and effect.
            </p>
            <h4>16. REVISIONS TO THIS AGREEMENT</h4>
            <p>
              Apruvd may at any time revise this Agreement by updating this posting. By using the Site or by using the
              Services, you agree to be bound by any such revisions and should therefore periodically visit this page to
              determine the then current terms to which you are bound.
            </p>
            <h4>17. NOTICES</h4>
            <p>
              Except as explicitly stated otherwise, any notices shall be given by postal mail to Apruvd, LLC Attn: Tom
              Belgrad, 2999 North 44th Street, Suite 630, Phoenix, AZ 85018 (in the case of Apruvd); or to the email
              address you provide to Apruvd during the registration process (in your case). Notice shall be deemed given
              twenty-four (24) hours after email is sent, unless the sending party is notified that the email address is invalid.
              Alternatively, we may give you notice by certified mail, postage prepaid and return receipt requested, to the
              address provided to Apruvd during the registration process. In such case, notice shall be deemed given three (3)
              days after the date of mailing.
            </p>
            <p>&copy; 2018-2022 Apruvd, LLC</p>
          </Col>
        </Row>
        <Footer />
      </div>
    )
  }
}

export default Terms
