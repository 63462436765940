import React, { Component } from 'react'

class CTA extends Component {
  render() {
    return (
      <a className="cta" href={this.props.to}>
        <h6>{this.props.title}</h6>
        <span>{this.props.text}</span>
      </a>
    )
  }
}

export default CTA
