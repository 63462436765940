import React, { Component } from 'react'

class Image extends Component {
  render() {
    return (
      <img src={this.props.imagesrc} style={{width: "100%"}} alt={this.props.caption} />
    )
  }
}

export default Image
