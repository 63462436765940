import React, { Component } from 'react'
import animate, { stop } from 'animateplus'

class BulletPointSVG extends Component {

  fadeIn = (e) => {
    let target = e.target.parentElement.nextSibling
    if (e.target.nodeName === 'circle')
      target = e.target.parentElement.parentElement.nextSibling
    stop(target)  
    animate({
      elements: target,
      delay: 0,
      duration: 2200,
      easing: "out-cubic",
      opacity: [0, 1]
    })
  }

  fadeOut = (e) => {
    let target = e.target.parentElement.nextSibling
    if (e.target.nodeName === 'circle')
      target = e.target.parentElement.parentElement.nextSibling
    stop(target)  
    animate({
      elements: target,
      delay: 0,
      duration: 1200,
      easing: "out-cubic",
      opacity: [1, 0]
    })
  }

  render() {
    return (
      <div className={`abt-svg ${this.props.className}`} onMouseEnter={this.fadeIn.bind(this)} onMouseLeave={this.fadeOut.bind(this)}>
        <svg className={`abt-bullet ${this.props.className}-bullet`} xmlns="http://www.w3.org/2000/svg" width="1.25vw" height="1.25vw" viewBox="0 0 25 25">
          <defs>
            <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
              <stop offset="0" stopColor="#3fffb1"/>
              <stop offset="1" stopColor="#b1ff57"/>
            </linearGradient>
          </defs>
          <circle cx="12.5" cy="12.5" r="12.5" fill="url(#linear-gradient)"/>
        </svg>
        <svg className={`abt-shadow ${this.props.className}-shadow`} xmlns="http://www.w3.org/2000/svg" width="1.25vw" height="1.25vw" viewBox="0 0 25 25">
          <circle cx="12.5" cy="12.5" r="12.5" fill="#000" fillOpacity="0.0" />
        </svg>
      </div>
    )
  }
}

export default BulletPointSVG
